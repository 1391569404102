import React from 'react';
import {Box, Button} from '@mui/material';

const FormStepper = ({isLastStep, isFirstStep, onNext, onBack, onSubmit, isNextDisabled}) => {

    return (
        <Box mt={2} display='flex' flexDirection='column' alignItems='center' justifyContent='center'>
            <Box display="flex" gap={1} flexDirection={{ xs: 'column', sm: 'row'}}>

                { !isFirstStep && onBack && (
                    <Button 
                        variant='contained'
                        color='secondary'
                        onClick={onBack}
                        sx={{
                            width: {xs:'100%', sm: 'auto'},
                           
                        }}
                        >
                        Back 
                        </Button>
                )}
                <Button 
                    variant="contained"
                    color="primary"
                    onClick={isLastStep ? onSubmit : onNext}
                    disabled={isNextDisabled}
                    sx={{ width: { xs: '100%', sm: 'auto' } }}
                >
                    {isLastStep? 'Submit': 'Next'}
                </Button>
            </Box>
        </Box>
    );
};

export default FormStepper;