
import home1 from './home1.png';
import home3 from './home3.png';
import home4 from './home4.png';
import home5 from './home5.png';
import home6 from './home6.png';
import home7 from './home7.png';
import home8 from './home8.png';
import home9 from './home9.png';
import buyers from './buyers.png';
import companies from './companies.png';
import sellers from './sellers.png';
import logo from './logo.png';
import google from './google-icon.png'



const images = {
  home1,
  home3,
  home4,
  home5,
  home6,
  home7,
  home8,
  home9,
  buyers,
  sellers,
  companies,
  logo,
  google
};

export default images;