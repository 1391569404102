import React from 'react';
import { Typography, Box, Paper } from '@mui/material';

const Press = (company) => {
    return (
        <Box component={Paper} style={{ padding: '40px', marginTop: '20px' }}>
            <Typography variant="h4" style={{ color: 'black', marginBottom: 20 }}>
                Press
            </Typography>
            {/* You can add links or content related to press here */}
            <Typography variant="body1">
                {/* Placeholder text; replace with actual press information */}
                Check out our latest press releases and media coverage for {company.name}
            </Typography>
            {/* Add more specific content as needed */}
        </Box>
    );
};

export default Press;
