import React from 'react';
import { LinearProgress, Typography } from '@mui/material';

// Custom component for displaying the shares with a progress bar
const AvToTotal = ({ current, total }) => {
    const progress = (current / total) * 100; // Calculate the progress percentage

    return (
        <div style={{ width: '100%' }}>
            <Typography variant="body2" component="span">
                {current}/{total}
            </Typography>
            <LinearProgress variant="determinate" value={progress} style={{ marginTop: '5px' }} />
        </div>
    );
};

export default AvToTotal;
