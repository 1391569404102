import React,{useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../axiosInstance'; // Ensure this import points to your axios instance
import Cookies from 'js-cookie';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Alert, Typography } from '@mui/material';

const DealStatusButton = ({ status, company, dealId }) => {
    const navigate = useNavigate();

    const [dialogOpen, setDialogOpen] = useState(false);
    const [investmentAmount, setInvestmentAmount] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleButtonClick = async () => {
       
        try {

            if (status === 'INVEST_NOW') {
                navigate(`/deals/${dealId}`);

            } else if (status === 'OVERSUBSCRIBED') {
               navigate(`/waiting/${dealId}`);

            } else if (status === 'COMING_SOON') {
                setDialogOpen(true);
            } else {
                alert('This option is not available at the moment.');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('Something went wrong while processing your request. Please try again later.');
        }
    };

    const statusConfig = {
        INVEST_NOW: {
            text: 'Invest Now',
            color: 'secondary',
            variant: 'contained',
        },
        COMING_SOON: {
            text: 'Register Interest',
            color: 'secondary',
            variant: 'bright',
        },
        OVERSUBSCRIBED: {
            text: 'Join Waiting List',
            color: 'secondary',
            variant: 'bright',
        },
    };

    const { text, color, variant } = statusConfig[status] || {
        text: 'Unknown',
        color: 'info',
        variant: 'text',
    };

    const handleInvestmentSubmit = async () => {
        if (!investmentAmount) {
            setErrorMessage('Please enter an investment amount.'); 
            return;
        }
        
        try {
            const config = {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-CSRFToken': Cookies.get('csrftoken'),
                },
            };
            const interestResponse = await api.post(`/api/products/interest_list/${company.id}/`, { "investment_amount":investmentAmount}, config);
            setDialogOpen(false); 
            alert(`${interestResponse.data.message}`);
            
        } catch (error) {
            console.error('Error:', error);
            setErrorMessage('Something went wrong while processing your request. Please try again later.'); 
        }
    };


    const handleDialogClose = () => {
        setDialogOpen(false);
        setInvestmentAmount(''); 
        setErrorMessage(''); 
    };

    return (
        <>
        <Button
            variant={variant}
            size="small"
            color={color}
            onClick={handleButtonClick}
        >
            {text}
        </Button>
          <Dialog open={dialogOpen} onClose={handleDialogClose}>
          <DialogTitle>Thank you for your interest in {company.name}! </DialogTitle>
          <DialogContent>
            
            <Typography variant='body1' paddingBottom={'20px'}>
            Please let us know the investment amount you're interested in, and we'll notify you as soon as this company's shares are available for trading.
            </Typography>
              <TextField
                  autoFocus
                  margin="dense"
                  label="Investment amount"
                  type="number"
                  fullWidth
                  variant="outlined"
                  value={investmentAmount}
                  onChange={(e) => {
                      setInvestmentAmount(e.target.value);
                      setErrorMessage(''); // Clear error message on change
                  }}
              />
              {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
          </DialogContent>
          <DialogActions>
              <Button variant='contained' size="small" color='grey' onClick={handleDialogClose}>Cancel</Button>
              <Button variant='contained' size="small" color='primary' onClick={handleInvestmentSubmit}>Register Interest</Button>
          </DialogActions>
      </Dialog>
  </>
);
};

export default DealStatusButton;

