import React from 'react';
import { Typography } from '@mui/material';

const FormTitle = ({ title }) => {
    return (
        <Typography
            padding="20px"
            variant="h4"
            sx={{ fontSize: { xs: '1rem', md: '2rem' } }}
            textAlign="center"
        >
            {title}
        </Typography>
    );
};

export default FormTitle;
