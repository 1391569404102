import React from 'react';
import { Box, TextField, Typography } from '@mui/material';

const InputStep = ({ step, formData, setFormData }) => {
    const handleInputChange = (questionId, value) => {
        setFormData((prevData) => ({
            ...prevData,
            [questionId]: value,
        }));
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'center', padding: { xs: 2, md: 4 } }}>
            <Typography variant="h6" gutterBottom sx={{
                fontSize: { xs: '1rem', md: '1.75rem' },
                textAlign: 'center'
            }}>
                {step.question.question} <span style={{ color: 'red' }}>*</span> {/* Required indicator */}
            </Typography>
            <TextField
                key={step.question.id}
                label="Your Answer"
                variant="outlined"
                fullWidth
                value={formData[step.question.id] || ''}
                onChange={(e) => handleInputChange(step.question.id, e.target.value)}
                error={!formData[step.question.id]} // Show error if input is empty
                helperText={!formData[step.question.id] ? "This field is required" : ""} // Helper text
                sx={{
                    width: { xs: '90%', sm: '70%', md: '50%' },
                    maxWidth: '400px',
                    mb: 2
                }}
            />
        </Box>
    );
};

export default InputStep;
