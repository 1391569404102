import React, { useState, useMemo } from 'react';
import { useTheme } from '@mui/material';
import { TableContainer, Paper, Grid } from '@mui/material';
import CombinedFilters from '../components/general/Filter';
import SearchBar from '../components/general/SearchBar';
import DataGridComponent from '../components/DataGridComponent';
import LoadingState from '../components/general/LoadingState';
import ErrorState from '../components/general/ErrorState';
import MarketplaceBanner from '../components/general/MarketplaceBanner';
import useFetchCompanies from '../hooks/useFetchCompanies'; 
import formatValuation from '../components/helpers/FormatValuation';
import getDisplayCurrency from  '../components/helpers/CurrencyFormatter';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom'; 

const Investments = ({ isAuthenticated }) => {
    const theme = useTheme();
    const { companies, loading, error } = useFetchCompanies(); // Using custom hook
    const [searchQuery, setSearchQuery] = useState('');
    const [dealStatusFilter, setDealStatusFilter] = useState('ALL');
    const [valuationRange, setValuationRange] = useState('ALL');
    const [sectorFilter, setSectorFilter] = useState('ALL');

    const limit1 = 10000000; // Lower limit for valuation
    const limit2 = 1000000000; // Upper limit for valuation

    const filteredCompanies = useMemo(() => {
        return companies.filter(company => {
            const matchesSearch = company.name.toLowerCase().includes(searchQuery.toLowerCase());
            const matchesDealStatus = dealStatusFilter === 'ALL' || company.deal_status === dealStatusFilter;
            const matchesValuation = (valuationRange === 'ALL') ||
                (valuationRange.startsWith('less_than_') && company.indicative_valuation < limit1) ||
                (valuationRange.startsWith('between_') && company.indicative_valuation >= limit1 && company.indicative_valuation <= limit2) ||
                (valuationRange.startsWith('more_than_') && company.indicative_valuation > limit2);
            const matchesSector = sectorFilter === 'ALL' || company.sector === sectorFilter;

            return matchesSearch && matchesDealStatus && matchesValuation && matchesSector;
        });
    }, [companies, searchQuery, dealStatusFilter, valuationRange, sectorFilter]);

    const getDisplayStatus = (status) => {
        const statusMap = {
            COMING_SOON: '🔜 Coming Soon',
            OVERSUBSCRIBED: '⏳ Oversubscribed',
            INVEST_NOW: '🚀 Live',
        };
    
        return statusMap[status] || 'Unknown'; // Fallback to 'Unknown' if status doesn't match
    };
    
    
    const rows = filteredCompanies.map(company => ({
        id: company.id,
        logo: company.logo,
        name: company.name,
        currency: company.currency,
        indicative_valuation: formatValuation(company.indicative_valuation), // Format the valuation
        change_percentage: company.change_percentage,
        num_investors: company.num_investors,
        available_shares: company.num_available_shares,
        deal_status: company.deal_status,
      
    }));

    const columns = [
        { field: 'logo', headerName: 'Logo', width: 100, renderCell: (params) => <img src={params.value} alt={params.row.name} style={{ width: '50px', paddingTop:'10px' }} /> },
        { field: 'name', headerName: 'Company', width: 150 }, 
        { 
            field: 'currency', 
            headerName: 'Currency', 
            width: 100,
            renderCell: (params) => (getDisplayCurrency(params.row.currency))
        }, 
        { field: 'indicative_valuation', headerName: 'Valuation', width: 120 }, 
        { field: 'change_percentage', headerName: 'Change (%)', width: 120 }, 
        { field: 'num_investors', headerName: 'Investors', width: 100 }, 
        { field: 'available_shares', headerName: 'Available Shares', width: 170},
        { 
            field: 'deal_status', 
            headerName: 'Deal Status', 
            width: 130,
            renderCell: (params) => (getDisplayStatus(params.row.deal_status))
        },

        {/* 
        { 
            field: 'actions', 
            headerName: '', 
            width: 190, 
            renderCell: (params) => (
                <DealStatusButton
                    status={params.row.deal_status} 
                    companyId={params.row.id} 
                />
            ) 
        },
        */}
    ];
    const navigate = useNavigate(); 

    return (
        <Grid container justifyContent={'center'}>
            <MarketplaceBanner />
            <Grid item container xs={12} lg={10} display='flex' spacing={2} padding='40px'>
                <TableContainer component={Paper}>
                    <Grid container>
                        <CombinedFilters
                            dealStatusValue={dealStatusFilter}
                            onDealStatusChange={setDealStatusFilter}
                            valuationValue={valuationRange} 
                            onValuationChange={setValuationRange} 
                            sectorValue={sectorFilter}
                            onSectorChange={setSectorFilter}
                        />
                        <SearchBar searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
                    </Grid>
                    <Grid>
                        {loading && <LoadingState theme={theme} />}
                        {error && <ErrorState error={error} theme={theme} />}
                        {!loading && !error && (
                            <DataGridComponent
                                rows={rows}
                                columns={columns}
                                isAuthenticated={isAuthenticated}
                                onRowClick={(params) => {
                                    const companyId = params.row.id; 
                                    navigate(`/company/${companyId}`); 
                                }}
                            />
                        )}
                    </Grid>
                </TableContainer>
            </Grid>
        </Grid>
    );
};

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps)(Investments);
