import React from 'react';
import { Box, Typography, Paper, Grid } from '@mui/material';

const AccountCreatedMessage = () => (
    <div style={{ padding: 20 }}>
        <Grid flexDirection="column" alignItems="center">
            <Box component={Paper} style={{ padding: '60px' }}>
                <Typography variant="h4" gutterBottom>
                    Your account has been created.
                </Typography>
                <Typography variant="body1">
                    Please check your email for a link to activate your account.
                </Typography>
            </Box>
        </Grid>
    </div>
);

export default AccountCreatedMessage;
