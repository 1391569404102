import { useState, useEffect } from 'react';
import api from '../axiosInstance';

export const useOrderData = (dealId) => {
  const [deal, setDeal] = useState(null);
  const [company, setCompany] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    let mounted = true;
    
    const fetchData = async () => {
      if (!dealId) {
        if (mounted) setLoading(false);
        return;
      }

      if (mounted) setLoading(true);
      
      try {
        const dealResponse = await api.get(`/api/products/deals/id/${dealId}/`);
        if (!mounted) {
          return;
        }
        
        const dealData = dealResponse.data;
        setDeal(dealData);

        const companyResponse = await api.get(`/api/products/companies/${dealData.company}/`);
        if (!mounted) {
          return;
        }
        
        setCompany(companyResponse.data);
        
        if (mounted) {
          setLoading(false);
        }
      } catch (err) {
       
        if (mounted) {
          setError(err.message || 'Failed to load data');
          ;
          setLoading(false);
        }
      }
    };

    fetchData();

    return () => {
      mounted = false;
    };
  }, [dealId]);


  // Calculate final loading state
  const isLoading = loading || (!deal || !company);
  
  return { 
    deal, 
    company, 
    loading: isLoading,
    error 
  };
};

export default useOrderData;