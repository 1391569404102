import React, { useEffect, useState } from 'react';
import { Paper, Grid } from '@mui/material';
import VerificationResult from '../components/VerificationResult'; // Import the VerificationResult component

const OnFidoVerificationPage = () => {
    const [verificationComplete, setVerificationComplete] = useState(false);
    const [verificationData, setVerificationData] = useState(null);
    
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const workflowRunId = urlParams.get('workflowRunId');
        const sdkToken = urlParams.get('sdkToken');

        const initOnfido = () => {
            if (window.Onfido) {
                window.Onfido.init({
                    token: sdkToken,
                    workflowRunId: workflowRunId,
                    containerId: 'onfido-container',

                    onComplete: (data) => {
                        console.log('Verification completed:', data);
                        setVerificationData(data); // Save the verification result data
                        setVerificationComplete(true); // Mark verification as complete
                    },

                    onError: (error) => {
                        console.error('Onfido error:', error);
                    },
                });
            } else {
                console.error('Onfido SDK not loaded');
            }
        };

        // Retry until Onfido is available, if it's not loaded yet
        const intervalId = setInterval(() => {
            if (window.Onfido) {
                clearInterval(intervalId);
                initOnfido();
            }
        }, 100);

        // Cleanup interval when component unmounts
        return () => clearInterval(intervalId);

    }, []);

    return (
        <Grid container xs={12} lg={10} display='flex' justifyContent={'center'} spacing={2} padding='40px'>
            {verificationComplete ? (
                // Render the VerificationResult component if verification is complete
                <VerificationResult resultData={verificationData} />
            ) : (
                // Render the Onfido SDK container if verification is not complete
                <Grid item xs={12} lg={10} component={Paper}>
                    <h1>Identity Verification</h1>
                    <div id="onfido-container"></div>
                </Grid>
            )}
        </Grid>
    );
};

export default OnFidoVerificationPage;
