import {
    LOAD_INVESTOR_PROFILE_FAIL,
    LOAD_INVESTOR_PROFILE_SUCCESS,
    UPDATE_INVESTOR_PROFILE_FAIL,
    UPDATE_INVESTOR_PROFILE_SUCCESS
} from '../actions/types';

const initialState = {
    investing_anticipation: '', 
    target_investment_per_investment: '',
    interested_investment_solution: '',
    other_assets: '', 
    investment_horizon: '',
    risk_preference: '', 
    investment_experience: '', 
    liquid_worth: '',
    risk_factors: '',
    qualified_purchaser: false,
    investor_type: '',
    accredited_investor_status: '',
    first_name: '',
    last_name: '',
    dob: null,
    nationality: '',
    referral_source: '',
    investment_method: '',
    industry_interests: []
};

// Utility function to update the profile
const updateProfile = (state, profile) => ({

    investing_anticipation: profile.investing_anticipation || '',
    target_investment_per_investment: profile.target_investment_per_investment || '',
    interested_investment_solution: profile.interested_investment_solution || '',
    other_assets: profile.other_assets || '',
    investment_horizon: profile.investment_horizon || '',
    risk_preference: profile.risk_preference || '',
    investment_experience: profile.investment_experience || '',
    liquid_worth: profile.liquid_worth || '',
    risk_factors: profile.risk_factors || '',
    qualified_purchaser: profile.qualified_purchaser !== undefined ? profile.qualified_purchaser : false,
    investor_type: profile.investor_type || '',
    accredited_investor_status: profile.accredited_investor_status || '',
    first_name: profile.first_name || '',
    last_name: profile.last_name || '',
    dob: profile.dob || null,
    nationality: profile.nationality || '',
    referral_source: profile.referral_source || '',
    investment_method: profile.investment_method || '',
    industry_interests: profile.industry_interests || []
});

const profile = function(state = initialState, action) {
    const { type, payload } = action;

    switch(type) {
        case UPDATE_INVESTOR_PROFILE_SUCCESS:
        case LOAD_INVESTOR_PROFILE_SUCCESS:
            return updateProfile(state, payload.profile);

        case LOAD_INVESTOR_PROFILE_FAIL:
            return initialState;  // Reset to initial state on fail

        case UPDATE_INVESTOR_PROFILE_FAIL:
            return state;

        default:
            return state;
    }
};

export default profile;
