import React, { useState, useEffect } from 'react';
import { register } from '../actions/auth';
import { connect } from 'react-redux';
import { Typography, Paper, Grid, Divider, CircularProgress } from '@mui/material';
import { useTheme } from '@mui/material';
import RegisterForm from '../components/register/RegisterForm';
import GoogleSignUpButton from '../components/register/GoogleSignUpButton';
import AccountCreatedMessage from '../components/register/AccountCreatedMessage';
import LoginButton from '../components/general/LoginButton';

const Register = ({ register, isAuthenticated, error }) => {
    const theme = useTheme();
    const [accountCreated, setAccountCreated] = useState(false);
    const [backendErrors, setBackendErrors] = useState({});
    const [loading, setLoading] = useState(false); 


    useEffect(() => {
        
        if (error) {
            setBackendErrors(error);
            setAccountCreated(false); 
        } else {
            setBackendErrors({});
            if (isAuthenticated) {
                setAccountCreated(prev => true);  // Use functional update here
            }
        }
    }, [error, isAuthenticated]);

    const handleFormSubmit = async (e, formData) => {
        e.preventDefault();
        const { email, password, re_password } = formData;

        setBackendErrors({});
        setLoading(true);

        try {
            await register(email, password, re_password);
        } catch (err) {
            console.error('Error during registration:', err);
        } finally {
            setLoading(false);
        }
    };

    // Show account created message if successful
    if (accountCreated) {
        return <AccountCreatedMessage />;
    }



    return (
        <div style={{ display: 'flex', padding: 20, backgroundColor: theme.palette.primary.main, color: 'white', justifyContent: 'center' }}>
            <Grid item xs={12} lg={10} display='flex' padding='40px'>
                <Grid component={Paper} style={{ padding: '60px' }}>
                    <Typography variant="h4" gutterBottom>
                        Register to start investing
                    </Typography>
                    {loading ? ( 
                        <CircularProgress color="secondary" />
                    ) : (
                        <RegisterForm onSubmit={handleFormSubmit} backendErrors={backendErrors} />
                    )}
                    <Divider>or</Divider>
                    <GoogleSignUpButton />
                    <Grid display={'flex'} justifyContent={'center'} flexDirection={'column'} alignContent={'center'}>
                        <Typography variant="body1" style={{ marginTop: '30px', alignContent: 'center' }}>
                            Already have an account?
                        </Typography>
                        <LoginButton />
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated,
    error: state.auth.error, // Ensure error matches your reducer's state
 
});

export default connect(mapStateToProps, { register })(Register);
