import React, { useState } from "react";
import ScreeningForm from "../components/ScreeningForm";
import { connect } from 'react-redux';
import { Grid, Button, Paper, Box } from '@mui/material';
import { useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import api from '../axiosInstance';
import Cookies from 'js-cookie';
import Loading from '../components/general/Loading'; // Import your Loading component

const forms = [['Verification', 'register_verification'], ['Agreements', 'register_agreements'], ['Profile', 'register_profile'], ['Survey', 'register_survey'], ['Goals', 'register_goals']]

const Screen = ({ register, isAuthenticated, profileData }) => {
  const [formsCompleted, setFormsCompleted] = useState([1, 0, 0, 0, 0]);
  const [loading, setLoading] = useState(false); // State for loading



  const theme = useTheme();
  const navigate = useNavigate();

  const handleFormComplete = (formIndex) => {
    const updatedFormsCompleted = [...formsCompleted];
    updatedFormsCompleted[formIndex] = 2;
    if (formIndex < 4) {
      updatedFormsCompleted[formIndex + 1] = 1;
    }
    setFormsCompleted(updatedFormsCompleted);
  };


  const navigateToKYC = () => {
    setLoading(true); // Set loading to true when starting the API call
    const config = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRFToken': Cookies.get('csrftoken')
      }
    };

    const body =
    {
      "email_type": "screening_confirmation",
      "recipient_email": "zuzanna.onderko@gmail.com",
      "context": {
        "user_name":  `${profileData.first_name} ${profileData.last_name}`
      }
    }
    
    api.post('/api/comm/email/send/screening_confirmation/', body, config)
      .then((response) => {
      })
      .catch((error) => {
        console.error('There was a problem with the API call:', error);
      })
      .finally(() => {
        setLoading(false);
        navigate('/profile?tab=3');
      });
  };

  return (
    <div>
      <Grid container spacing={2} justifyContent='center' padding='10px'>
        <Grid item xs={12} lg={10} padding='10px'>
          <Paper sx={{ padding: '20px' }}>
            {formsCompleted.map((status, index) => (
              <Box key={index} sx={{ borderRadius: '10px', bgcolor: status === 0 ? 'grey.300' : status === 1 ? 'white' : theme.palette.secondary.light, padding: '20px', m: 2 }}>
                {status === 0 && `${forms[index][0]} Section Awaiting Completion`}
                {status === 1 &&
                  <div>
                    <ScreeningForm formName={forms[index][1]} onFormComplete={() => handleFormComplete(index)} />
                  </div>
                }
                {status === 2 && `${forms[index][0]} Section Completed`}
              </Box>
            ))}
            {formsCompleted.every(status => status === 2) && (
              <Button 
              variant='contained' 
              color='primary' 
              disabled={loading} 
              onClick={navigateToKYC}>Complete and proceed to Verification</Button>

            )}

            {loading && <Loading />} {/* Show loading component when loading is true */}
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = state => ({
  isAuthenticated: state.isAuthenticated,
  profileData: state.profile
});

export default connect(mapStateToProps)(Screen);
