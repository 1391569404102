import React from 'react';
import { Box, TextField, Typography } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'; // Changed to AdapterDateFns
const DateStep = ({ step, formData, setFormData }) => {
    const handleDateChange = (questionId, date) => {
        setFormData((prevData) => ({
            ...prevData,
            [questionId]: date,
        }));
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'center' }}>
            <Typography variant="h6" gutterBottom sx={{
                    fontSize: { xs: '1rem', md: '1.75rem' },
                    textAlign: 'center'
                }}>
                {step.question.question} <span style={{ color: 'red' }}>*</span> {/* Required indicator */}
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                    label="Select Date"
                    value={formData[step.question.id] || null}
                    onChange={(newValue) => handleDateChange(step.question.id, newValue)}
                    renderInput={(params) => (
                        <TextField 
                            {...params} 
                            sx={{ width: '50%' }} 
                            error={!formData[step.question.id]} // Show error if date is not selected
                            helperText={!formData[step.question.id] ? "Date is required" : ""} // Help text
                        />
                    )}
                />
            </LocalizationProvider>
        </Box>
    );
};

export default DateStep;
