import { combineReducers } from "redux";
import auth from './auth';
import profile from './profile';
import banking from './banking';
import order from './order';

export default combineReducers({
    auth,
    profile,
    banking,
    order
})