import React, { useState, useEffect } from 'react';
import { FormControl, InputLabel, OutlinedInput, Grid, Button, Typography, IconButton } from '@mui/material';
import { connect } from 'react-redux'; // Import connect for Redux
import CSRFToken from '../general/CSRFToken';
import { deleteAccount } from '../../actions/auth'; // Import the delete account action
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { InsertDriveFile } from '@mui/icons-material'; // Import file icon
import api from '../../axiosInstance';

const BasicProfile = ({ deleteAccount }) => {
    const navigate = useNavigate(); // Get the navigate function
    const [formData, setFormData] = useState({
        current_password: '',
        new_password: '',
        confirm_new_password: ''
    });
    const [documents, setDocuments] = useState([]); // State to hold documents
    const { current_password, new_password, confirm_new_password } = formData;

    const onChange = e => setFormData({
        ...formData,
        [e.target.name]: e.target.value
    });

    const onSubmit = e => {
        e.preventDefault();
        // Placeholder for the password update logic
    };

    const handleDeleteAccount = () => {
        const confirmDelete = window.confirm('Are you sure you want to delete your account? This action cannot be undone.');
        if (confirmDelete) {
            deleteAccount(navigate); // Call the delete account action
        }
    };


    // Fetch documents on component initialization
    useEffect(() => {
        const fetchDocuments = async () => {
            try {
                const response = await api.get('/api/documents/list_doc/');
                const formattedDocs = response.data.map(doc => ({
                    id: doc.id,
                    label: doc.document_type // Assuming `document_type` holds the title
                }));
                setDocuments(formattedDocs);
            } catch (error) {
                console.error("Error fetching documents", error);
            }
        };

        fetchDocuments();
    }, []);


    const downloadDocument = async (documentId) => {
        try {
            const response = await api.get(`/api/documents/download_doc/${documentId}/`, {
                responseType: 'blob' // Important for file download
            });
            // Create a URL for the downloaded file and trigger the download
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `document_${documentId}.pdf`); // Name the file
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (error) {
            console.error("Error downloading document", error);
        }
    };

    return (
        <Grid>
        <Grid>
        <form onSubmit={e => onSubmit(e)}>
        <CSRFToken />
          
             
            <Typography variant="h6" gutterBottom>
                Basic Data
            </Typography>
            <Typography variant="body1" gutterBottom>
                Update Password
            </Typography>
            <Grid container spacing={2} flexDirection={'column'}>
                <Grid item xs={12} sm={6}>
                    <FormControl variant='outlined' fullWidth margin='normal'>
                        <InputLabel htmlFor='current_password'>Current Password</InputLabel>
                        <OutlinedInput
                            id='current_password'
                            type='password'
                            label='Current Password'
                            name='current_password'
                            value={current_password}
                            onChange={e => onChange(e)}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl variant='outlined' fullWidth margin='normal'>
                        <InputLabel htmlFor='new_password'>New Password</InputLabel>
                        <OutlinedInput
                            id='new_password'
                            type='password'
                            label='New Password'
                            name='new_password'
                            value={new_password}
                            onChange={e => onChange(e)}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl variant='outlined' fullWidth margin='normal'>
                        <InputLabel htmlFor='confirm_new_password'>Confirm New Password</InputLabel>
                        <OutlinedInput
                            id='confirm_new_password'
                            type='password'
                            label='Confirm New Password'
                            name='confirm_new_password'
                            value={confirm_new_password}
                            onChange={e => onChange(e)}
                        />
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <Button variant="contained" color='secondary' type="submit">
                        Update Password
                    </Button>
                </Grid>
              
            </Grid>
        </form>
        </Grid>
        <Grid>
       {/* Signed Documents Section */}
       <Typography variant="h6" gutterBottom style={{ marginTop: '2rem' }}>
                Signed Documents
            </Typography>
            <Grid container spacing={2} padding={2}>
                {documents.map((doc, index) => (
                    <Grid item xs={3} sm={2} md={1} key={index} style={{ textAlign: 'center' }}>
                        <IconButton
                            color="secondary"
                            onClick={() => downloadDocument(doc.id)} 
                            
                            style={{ display: 'flex', flexDirection: 'column', padding:'10px'}}
                        >
                            <InsertDriveFile fontSize="large" />
                            <Typography variant="caption" style={{ marginTop: '0.5rem' }}>
                                {doc.label}
                            </Typography>
                        </IconButton>
                    </Grid>
                ))}
            </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
                    {/* Add the delete button */}
                    <Button
                        variant="contained"
                        color="error"
                        onClick={handleDeleteAccount}
                    >
                        Delete Account
                    </Button>
                </Grid>
        </Grid>
    );
};

// Connect the component to Redux and map the deleteAccount action
export default connect(null, { deleteAccount })(BasicProfile);
