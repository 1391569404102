export const checkCondition = (condition, data) => {
    const { questionId, answer } = condition;
    if (typeof answer === 'object' && 'not' in answer) {
        return data[questionId] !== answer.not;
    }
    return data[questionId] === answer;
};

export const filterStepsByCondition = (steps, data) => {
    return steps.filter(step => {
        if (!step.condition) return true;
        const { all, any } = step.condition;
        if (all) return all.every(condition => checkCondition(condition, data));
        if (any) return any.some(condition => checkCondition(condition, data));
        return true;
    });
};

export const loadFormData = async (formName) => {
    const response = await import(`../../content/${formName}.json`);
    return response;
};
