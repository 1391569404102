import React from 'react';
import { Typography, Grid, Paper } from '@mui/material';
import NumberFormatter from './helpers/NumberFormatter';

const CompanySummary = ({ company }) => (

    <Grid container item xs={12} lg={12} component={Paper} flexDirection="row" alignItems="center">
        <Grid item xs={12} lg={2}>
            <img alt='logo' src={company.logo} style={{ margin: '40px', borderRadius: 5, height: '10vw' }} />
        </Grid>
        <Grid item xs={0} lg={1}>

        </Grid>
        <Grid item xs={12} lg={8} style={{ paddingTop: '40px' }}>
            <Grid>
            <Typography variant="h3" style={{ color: 'black', padding: '20px' }}>{company.name}</Typography>
            </Grid>
            <Grid container spacing={1} style={{ padding: '20px', backgroundColor: '#f7f7f7', borderRadius: '20px' }}>
            {[
                    { label: 'Founded', value: company.founded_date },
                    { label: 'Notable Investors', value: company.notable_investors?.join(', ') || 'No investors listed' },
                    { label: 'Headquarters', value: company.headquarters },
                    { 
                        label: 'Total Funding', 
                        value: (
                            <>
                                {company.currency}{' '}
                                <NumberFormatter number={company.total_funding} />
                            </>
                        ) 
                    }
                ].map(({ label, value }, index) => (
                    <Grid item xs={12} lg={3} key={index}>
                      <Typography variant="h6" sx={{ fontSize: { xs: '0.9rem', lg: '1rem' } }}>
                            {label}
                        </Typography>
                        <Typography variant="body1" sx={{ fontSize: { xs: '0.8rem', lg: '0.9rem' }, color: 'text.secondary' }}>
                            {value}
                        </Typography>
                    </Grid>
                ))}
            </Grid>
        </Grid>
    </Grid>
);

export default CompanySummary;
