import React, { useEffect, useState } from 'react';
import api from '../axiosInstance';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { useSelector } from 'react-redux';

const LogoCarousel = () => {
    const [displayLogos, setDisplayLogos] = useState([]);
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);


    useEffect(() => {

        const fetchLogos = async () => {
            try {
              const response = await api.get('/api/products/logos/');
              const logosData = response.data;
              
              // Always create enough duplicates for smooth scrolling
              let logosToShow = [];
              // Create 5 sets of duplicates to ensure enough slides
              for (let i = 0; i < 5; i++) {
                logosToShow = [...logosToShow, ...logosData];
              }
              
              setDisplayLogos(logosToShow);
            } catch (error) {
              console.error('Error fetching logos:', error);
            }
          };
      
          fetchLogos();
        }, []);


     
  const settings = {
    modules: [Autoplay, Pagination, Navigation],
    spaceBetween: 30,
    slidesPerView: 'auto',
    breakpoints: {
      480: {
        slidesPerView: 3,
        slidesPerGroup: 1,
      },
      768: {
        slidesPerView: 6,
        slidesPerGroup: 1,
      },
      1024: {
        slidesPerView: 10,
        slidesPerGroup: 1,
      }
    },
    loop: true,
    autoplay: {
      delay: 1000,
      disableOnInteraction: false,
    },
    speed: 800,
    allowTouchMove: true,
    loopAdditionalSlides: 10,
    watchSlidesProgress: true,
    className: "mySwiper",
    style: {
      width: '100%',
      padding: '20px',
      margin: '0 auto'
    }
  };

  if (!displayLogos.length) return null;

    return (
        <div className='logo-carousel-container' position='relative'>
            <Swiper{...settings}>
                {displayLogos.map(
                    (company, index) => (
                        <SwiperSlide  key={`${company.id}-${index}`} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                            <a href={isAuthenticated ? `/company/${company.id}` : '/register'}>
                                <img src={company.logo} alt={company.name} style={{ filter: 'grayscale(100%)', width: '50px', height: '50px', maxWidth: '100%' }} />
                            </a>

                        </SwiperSlide>
                    )
                )
                }
            </Swiper>
        </div >
    );
};

export default LogoCarousel;