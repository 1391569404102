import React from 'react';
import { Grid, Typography, Button, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material';
import images from '../images';
import LogoCarousel from '../components/CompaniesCarousel';
// TODO: redo this file so its smaller 

function Home() {
    const theme = useTheme();

    const styles = {
        container: {
            display: 'flex',
            justifyContent: 'space-evenly',
            alignItems: 'center',
            backgroundColor: 'white',
            padding: '20px',
        },
        header: {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
    };




    return (
        <div>

                       {/* ROW INTRO  */}
            <Grid container spacing={2} style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.text.light,
                padding: '20px'
            }}>
                <Grid item xs={12} lg={5} display='flex' flexDirection='column' alignItems='center'>
                    <Typography variant="h3" style={{
                        fontWeight: 'bold',
                        textAlign: 'center',
                        padding: '20px'
                    }}>
                        Invest in Private Companies <br /> made simple
                    </Typography>
                    <Typography variant="h5" style={{
                        padding: '20px',
                        textAlign: 'center',
                        fontWeight: 'bold'
                    }}>
                        Join now to access companies before they trade publicly <br />
                    </Typography>
                    <Button variant="contained" color='secondary' component={Link} to="/investments">
                        Invest in The Future
                    </Button>
                    <Typography variant="body1" padding='20px'>
                        Investing in private companies is highly speculative and involves a high degree of risk including the risk of substantial loss of investment.
                        <br>
                        </br>
                        <br></br>
                        Capital at risk.
                    </Typography>
                </Grid>
                <Grid item xs={12} lg={5}>
                    <img src={images.home1} alt="home1" style={{ width: '100%', borderRadius: 5 }} />
                </Grid>
            </Grid>

            
            {/* TODO : ARROW DOWN */}

            {/* ROW LOGOS  */}
            <Grid container style={{
                display: 'flex',
                backgroundColor: 'white',
              
            }}>
                <Grid item xs={12}>
                    <LogoCarousel />
                </Grid>
            </Grid>

            {/* ROW PORTFOLIO  */}
            <Grid container spacing={4} style={styles.container}>
                <Grid item xs={12} lg={4} display='flex' flexDirection='column' alignItems='center' justifyContent='flex-start' >

                <Typography variant="header" style={{
                                                ...styles.header,
                                                fontSize: '1.5rem', // Base font size for larger screens
                                                '@media (maxWidth: 600px)': {
                                                    fontSize: '1rem', // Smaller font size for mobile
                                                }
                                            }}>
                        Build your portfolio
                    </Typography>
                    <Typography variant='classic'  >
                        Invest in the most innovative Private Companies Stocks and Funds in Europe, UK and USA. Other charges may apply. See full pricing table here.
                    </Typography>
                    <Typography variant="link" component='a' href='/investments'>
                        View our range of Investment Opportunities
                    </Typography>
                </Grid>

                <Grid item xs={12} lg={4} display='flex' flexDirection='column' alignItems='center'>
                    <Box style={{

                        position: 'relative',
                        display: 'flex',
                        alignItems: 'flex-end',
                        justifyContent: 'center',
                        height: '100%',
                        marginBottom: '40px'
                    }}>
                        <Box style={{
                            backgroundColor: theme.palette.primary.main,
                            width: '100%',
                            height: '80%',
                            borderRadius: '15px',
                            position: 'absolute',
                            zIndex: 1
                        }}>
                        </Box>
                        <img src={images.home3} alt="home1" style={{

                            width: '80%',
                            position: 'relative',
                            zIndex: 2,
                        }} />
                    </Box>
                    <Typography variant="note" >
                        This is not investment advice. Always do your own research.
                    </Typography>
                </Grid>
            </Grid>

            {/* ROW LIQUIDITY  */}
            <Grid container spacing={4} style={styles.container}>

                <Grid item xs={12} lg={4} display='flex' flexDirection='column' alignItems='center' >
                    <Box style={{
                        position: 'relative',
                        display: 'flex',
                        alignItems: 'flex-end',
                        justifyContent: 'center',
                        height: '100%',
                        marginBottom: '40px'
                    }}>
                        <Box style={{
                            backgroundColor: theme.palette.primary.main,
                            width: '100%',
                            height: '100%',
                            borderRadius: '15px',
                            position: 'absolute',
                            zIndex: 1
                        }}>
                        </Box>
                        <img src={images.home4} alt="home1" style={{
                            padding: '30px',
                            margin: '20px',
                            width: '80%',
                            position: 'relative',
                            zIndex: 2,
                        }} />
                    </Box>

                    <Typography variant="note" >
                        This is not investment advice. Always do your own research.
                    </Typography>
                </Grid>

                <Grid item xs={12} lg={4} display='flex' flexDirection='column' alignItems='center'>

                <Typography variant="header" style={{
                    ...styles.header,
            fontSize: '1.5rem', // Base font size for larger screens
            '@media (maxWidth: 600px)': {
                fontSize: '1rem', // Smaller font size for mobile
            }
                }}>
                        Get Liquidity
                    </Typography>
                    <Typography variant="classic">
                        You can resell your investments to other users at any time with our secondary offering process. Market Conditions apply.
                    </Typography>
                    <Typography variant="link" component='a' href='/'>  View our Secondary Sale policy  </Typography>

                </Grid>
            </Grid>

            {/* ROW TAX EFFICIENCY  */}
            <Grid container spacing={4} style={styles.container}>

                <Grid item xs={12} lg={4} display='flex' flexDirection='column' alignItems='center' justifyContent='flex-start'>

                <Typography variant="header" style={{
                    ...styles.header,
            fontSize: '1.5rem', // Base font size for larger screens
            '@media (maxWidth: 600px)': {
                fontSize: '1rem', // Smaller font size for mobile
            }
                }} >
                        Invest tax-efficiently
                    </Typography>
                    <Typography variant="classic" >
                        Get access to different tax optimisation schemes to plan your savings and retirement plans.
                        <br />
                        Eligibility and tax rules apply. Please remember tax relief depends on your personal circumstances and current rules can change.
                    </Typography>
                    <Typography variant="link" component='a' href='/investments'> View our range of Investment Opportunities
                    </Typography>
                </Grid>
                <Grid item xs={12} lg={4} display='flex' flexDirection='column' alignItems='center' >
                    <Box style={{
                        position: 'relative',
                        display: 'flex',
                        alignItems: 'flex-end',
                        justifyContent: 'center',
                        height: '100%',
                        marginBottom: '40px'
                    }}>
                        <Box style={{
                            backgroundColor: theme.palette.primary.main,
                            width: '100%',
                            height: '80%',
                            borderRadius: '15px',
                            position: 'absolute',
                            zIndex: 1
                        }}>
                        </Box>
                        <img src={images.home5} alt="home1" style={{
                            position: 'relative',
                            zIndex: 2,
                            width: '80%'
                        }} />
                    </Box>

                    <Typography variant="note" >
                        This is not investment advice. Always do your own research.
                    </Typography>
                </Grid>
            </Grid>

            {/* ROW PRE IPO  */}
            <Grid container spacing={4} style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'stretch',
                backgroundColor: 'white',                padding: '20px',
                paddingTop: '80px',
                paddingBottom: '80px',
                height: '100%'
            }}>

                <Grid item xs={12} lg={4} display='flex' flexDirection='column' alignItems='space-between' 
                backgroundColor={theme.palette.secondary.main} borderRadius='10px' padding='40px' color={theme.palette.text.light}>
                    <Typography variant='h4'>
                        Get involved in Pre-IPO Deals from 1000£
                    </Typography>
                    <Typography variant='body2' paddingTop='30px'>
                        Normally reserved to a selected few Venture Capital Funds, EquitiBridge revolutionises the investment space, allowing its qualified clients to access pre-IPO dealings. View T&C.

                        <br />
                        <br />
                        Imagine you could buy the next Amazon, Facebook or Microsoft before it becomes public...🚀
                    </Typography>

                    <img src={images.home6} alt="home1" style={{ paddingTop:'30px', maxWidth: '60%' }} />
                    <img src={images.home7} alt="home1" style={{ paddingTop:'30px', alignSelf: 'flex-end', maxWidth: '60%' }} />

                </Grid>
                <Grid item xs={0} lg={1}>

                </Grid>
                <Grid item xs={12} lg={4} display='flex' flexDirection='column' alignItems='space-between' backgroundColor={theme.palette.background.grey} borderRadius='10px' padding='40px' >
                    <Typography variant='h4'>
                        Get fast, friendly customer service
                    </Typography>
                    <Typography variant='body1' paddingTop='30px'>
                        Our customer service team are here to answer your questions. We're here if you need extra support.
                    </Typography>


                    <img src={images.home8} alt="home5" style={{ paddingTop:'30px', maxWidth: '60%' }} />
                    <img src={images.home9} alt="home6" style={{ paddingTop:'30px', alignSelf: 'flex-end', maxWidth: '60%' }} />


                </Grid>
            </Grid>

            {/* ROW DOWNLOAD NOW  */}
            <Grid container spacing={4} style={{
                display: 'flex',
                justifyContent: 'space-evenly',
                alignItems: 'center',
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.text.light,
                padding: '20px'
            }}>

                <Grid item xs={12} lg={12} display='flex' flexDirection='column' alignItems='center'  >
                    <Typography variant="h4" gutterBottom>
                        Download the app to start investing now
                    </Typography>

                    <Button variant="contained" color='secondary' component={Link} to="/investments">
                        Get EquitiBridge now
                    </Button>


                    <Typography variant="body1" style={{ padding: '30px', textAlign: 'center', }}>
                        Investing in private companies is highly speculative and involves a high degree of risk
                        <br>
                        </br>
                        including the risk of substantial loss of investment.
                        <br>
                        </br>
                        <br>
                        </br>
                        Capital at risk.
                    </Typography>
                </Grid>
            </Grid>


        </div >
    );
}

export default Home;
