import React from 'react';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';

const LoginButton = () => (
    <Button
        variant="contained"
        color="secondary"
        component={Link}
        to="/login"
        style={{ marginTop: '30px' }}
    >
        Login
    </Button>
);

export default LoginButton;
