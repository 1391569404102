import React from 'react';

const NumberFormatter = ({ number, digitCount = 0 }) => {
    const formattedNumber = new Intl.NumberFormat('en-GB', {
        maximumFractionDigits: digitCount,
    }).format(number);

    return <span>{formattedNumber}</span>;
};

export default NumberFormatter;
