import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Typography, Button } from '@mui/material';

const ErrorPage = ({ errorCode }) => {
  return (
    <Container component="main" maxWidth="xs" style={{ textAlign: 'center', padding: '20px' }}>
      <Typography variant="h1" color="error">
        {errorCode || 'Error'}
      </Typography>
      <Typography variant="h5">
        Oops! Something went wrong.
      </Typography>
      <Typography variant="body1">
        We are experiencing technical difficulties. Please try again later.
      </Typography>
      <Button variant="contained" color="primary" component={Link} to="/">
        Go Back to Home
      </Button>
    </Container>
  );
};

export default ErrorPage;
