// VerificationResult.js
import React from 'react';
import { Paper, Grid } from '@mui/material';

const VerificationResult = ({ resultData }) => {
    return (
        <Grid container xs={12} lg={10} display='flex' justifyContent={'center'} spacing={2} padding='40px'>
            <Grid item xs={12} lg={10} component={Paper}>
                <h1>Verification Result</h1>
                <p>Status: {resultData.properties.result}</p>
                {/* Add any other result data you want to display */}
            </Grid>
        </Grid>
    );
};

export default VerificationResult;
