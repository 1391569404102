// constants.js

export const INVESTOR_TYPE_CHOICES = [
    ['institution', 'Institution'],
    ['individual', 'Individual']
];

export const ACCREDITED_STATUS_CHOICES = [
    ['business-assets', 'A business entity, with total assets exceeding $5 million'],
    ['business-investments', 'A business entity, with total investments exceeding $5 million'],
    ['business-accredited', 'A business entity, each of whose equity owners are accredited investors'],
    ['family-office', 'A family office, with total assets exceeding $5 million'],
    ['broker-dealer', 'A broker or dealer'],
    ['investment-adviser', 'An investment adviser registered with the SEC or any U.S. State'],
    ['exempt-advisor', 'An exempt reporting advisor with the SEC'],
    ['none', 'None of the above'],
    ['net-worth', 'My net worth, or my joint net worth with my spouse, exceeds $1 million, exclusive of the value of my primary residence'],
    ['ind-income', 'My individual income exceeded $200,000 in each of the past two years and I expect the same this year'],
    ['joint-income', 'My joint income with my spouse exceeded $300,000 in each of the past two years and I expect the same this year'],
    ['family-client', 'I am a Family Client of a Family Office'],
    ['sec-approved', 'I hold an SEC approved credential that qualifies me as an Accredited Investor']
];

export const INVESTMENT_HORIZON_CHOICES = [
    ['lt2', 'Less than 2 years'],
    ['2to5', 'Between 2 and 5 years'],
    ['5to7', 'Between 5 and 7 years'],
    ['gt7', 'Greater than 7 years']
];

export const RISK_PREFERENCE_CHOICES = [
    ['con', 'Conservative'],
    ['mod', 'Moderate'],
    ['sign', 'Significant'],
    ['agg', 'Aggressive']
];

export const LIQUID_WORTH_CHOICES = [
    ['lt150', 'Less than $150,000'],
    ['150to250', 'Between $150,000 and $250,000'],
    ['250to500', 'Between $250,000 and $500,000'],
    ['gt500', 'Greater than $500,000']
];

export const REFERRAL_SOURCE_CHOICES = [
    ['SE', 'Search Engine (e.g. Google)'],
    ['PD', 'Podcast'],
    ['TV', 'TV'],
    ['RD', 'Radio'],
    ['SM', 'Social Media'],
    ['CW', 'Co-worker'],
    ['FF', 'Friend or Family'],
    ['EC', 'Event or Conferences'],
    ['OO', 'Other']
];

export const INVESTMENT_METHOD_CHOICES = [
    ['IND', 'As an individual'],
    ['PIV', 'Personal Investment Vehicle (e.g. LLC)'],
    ['IRA', 'Self-Directed IRA'],
    ['401', '401(k) plan'],
    ['RLT', 'Revocable or Living Trust'],
    ['IT', 'Irrevocable Trust'],
    ['OO', 'Other']
];

export const INVESTMENT_ANTICIPATION_CHOICES = [
    ['lt20', 'Less than $20,000'],
    ['20to100', '$20,000 to $100,000'],
    ['gt100', 'Over $100,000'],
    ['none', 'None, I\'m just browsing'],
    ['ns', 'Not Sure']
];

export const TARGET_INVESTMENT_SIZE_CHOICES = [
    ['lt20', 'Less than $20,000'],
    ['20to50', '$20,000 to $50,000'],
    ['50to100', '$50,000 to $100,000'],
    ['100to250', '$100,000 to $250,000'],
    ['gt250', 'Over $250,000'],
    ['ns', 'Not Sure']
];

export const INVESTMENT_SOLUTIONS_CHOICES = [
    ['scf', 'Single Company Funds'],
    ['mcf', 'Multi-Company Funds'],
    ['dsa', 'Direct Share Acquisitions']
];

export const PAST_INVESTMENTS_CHOICES = [
    ['ls', 'Late stage/pre-IPO companies'],
    ['es', 'Early stage companies'],
    ['vf', 'Venture Funds'],
    ['hf', 'Hedge Funds'],
    ['re', 'Real Estate'],
    ['de', 'Debt'],
    ['ar', 'Art']
];
