import React from 'react';
import { Grid, Box } from '@mui/material';
import { styled } from '@mui/material';


const StyledBlob = styled(Grid, {
  shouldForwardProp: (prop) => prop !== 'variant',
  name: 'MuiGrid',
  slot: 'Root',
  overridesResolver: (props, styles) => {
    const { variant } = props;
    return [styles.root, variant && styles[variant]];
  },
})(({ theme, variant }) => ({
  alignItems: 'center',
  display: 'flex',
  ...(variant && theme.components.MuiGrid.variants.find((v) => v.props.variant === variant)?.style),
}));





const StyledBlobColumns = styled(Grid)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '20px',
 
})



const StyledBlobRows = styled(Grid)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
})



const StyledBlobSingleColumn = styled(Grid, {
  shouldForwardProp: (prop) => prop !== 'variant',
  name: 'MuiColumn',
  slot: 'Root',
  overridesResolver: (props, styles) => {
    const { variant } = props;
    return [styles.root, variant && styles[variant]];
  },
})(({ theme, variant }) => ({

  display: 'flex',
  flexDirection: 'column',
  alignItems:'space-around',
  justifyContent: 'space-between',
  padding: '40px',
  width: '50%',
  ...(variant && theme.components.MuiGrid.variants.find((v) => v.props.variant === variant)?.style),
}));



const StyledBlobImageContainer = styled(Box)({
  position: 'relative',    
  display: 'flex',    
  alignItems: 'flex-end',
  justifyContent: 'center',    
  height: '100%',    
  marginBottom: '40px'

})





const StyledIconBlobBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'variant',
  name: 'MuiIconBox',
  slot: 'Root',
  overridesResolver: (props, styles) => {
    const { variant } = props;
    return [styles.root, variant && styles[variant]];
  },
})(({ theme, variant }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'white',
  borderRadius: '50%',
  width: 50,
  height: 50,
  border: '1px solid grey',
  zIndex: 2,
  fontSize: '1rem',
  ...(variant && theme.components.MuiGrid.variants.find((v) => v.props.variant === variant)?.style),
}));



export const BlobIconBox = (props) => {
  return <StyledIconBlobBox {... props} />
}

export const Blob = (props) => {
  return <StyledBlob {...props} />;
};

export const BlobImageContainer = (props) => {
  return <StyledBlobImageContainer {...props} />
}


export const BlobColumns = (props) => {

  return <StyledBlobColumns {...props} />;
};

export const BlobRows = (props) => {

  return <StyledBlobRows {...props} />;
};

export const BlobSingleColumn = (props) => {
  return <StyledBlobSingleColumn {...props}/>
};