import React from 'react';
import { Typography, Grid, Paper } from '@mui/material';
import LineChart from './Chart'; // Import the new LineChart component
import { useTheme } from '@mui/material';

const Financials = (company) => {

    const theme = useTheme();
    const sharePriceYears = [new Date(2019, 0, 1), new Date(2020, 0, 1), new Date(2021, 0, 1), new Date(2022, 0, 1), new Date(2023, 0, 1)];
    const valuationYears = [new Date(2019, 0, 1), new Date(2020, 0, 1), new Date(2021, 0, 1), new Date(2022, 0, 1), new Date(2023, 0, 1)];
    const sharePriceValues = [2, 5.5, 2, 8.5, 1.5];
    const valuationValues = [0, 3.8, 253.8, 753.8, 1653];
    const lastUpdated = new Date(2024, 8, 8)
    const financialData = [
        { value: '$8.8B', description: 'Latest funding round valuation', variant: 'h5' },
        { value: '$20.45', description: 'Latest funding round price', variant: 'h5' },
        { value: 'Series H', description: 'Latest funding round security', variant: 'h5' },
        { value: '$1.1B', description: 'Cumulative funding', variant: 'h5' },
    ];


    return (
        <Grid>

            <Grid>

                <Typography variant="h4" padding='20px'>Financials</Typography>

                <Typography padding='20px'>
                    As of {lastUpdated.toString()}
                </Typography>

            </Grid>


            <Grid container spacing={1} justifyContent="space-around" style={{ marginTop: '30px', marginBottom:'30px' }}>
                {financialData.map((item, index) => (
                    <Grid
                        key={item.description}
                        item xs={12} lg={2}
                        component={Paper}
                        style={{ color: theme.palette.secondary.main, backgroundColor: theme.palette.secondary.light, padding: '20px', marginBottom: '20px' }}
                    >
                        <Typography variant={item.variant} style={{ color: theme.palette.secondary.dark }}>
                            {item.value}
                        </Typography>
                        <Typography variant='body1'>
                            {item.description}
                        </Typography>
                    </Grid>
                ))}
            </Grid>

            <Grid container spacing={2} justifyContent="space-between">
                <Grid item xs={12} sm = {6}>
                <LineChart 
                    title="Share Price History"
                    years={sharePriceYears}
                    values={sharePriceValues}
                    color={theme.palette.primary.main}
                />
                </Grid>
                <Grid item xs={12} sm = {6}>
                <LineChart 
                    title="Valuation History"
                    years={valuationYears} // Use the same years array for both charts
                    values={valuationValues}
                    color={theme.palette.secondary.main}
                />
                </Grid>
                
                
            </Grid>

        </Grid>
    );
};

export default Financials;
