import React, { Fragment, useEffect, useState } from 'react';
import { Skeleton, Box, AppBar } from '@mui/material';
import Navbar from '../components/general/Navbar';
import Header from '../components/general/Header';
import Footer from '../components/general/Footer';
import { connect } from 'react-redux';
import { checkAuthenticated } from '../actions/auth';
import { load_investor_profile } from '../actions/profile';

const Layout = ({ children, checkAuthenticated, load_investor_profile }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate loading data from the server
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    // Clean up the timer on component unmount
    return () => clearTimeout(timer);
  }, []);

  return (
    <Fragment>
      {loading ? (
        <>
          <AppBar position='sticky' sx={{ height:'64px', zIndex: 1300, backgroundColor: '#5acbf4'}}>
  
            <Skeleton variant="rectangular" width="100%" height={80} />
          </AppBar>
          <AppBar position="sticky" elevation={0} sx={{ top:'64px', zIndex:1300 + 1 }}>

            <Skeleton variant="rectangular" width="100%" height={50} />
          </AppBar>
          <Box mb={4} style={{ height: 'calc(100vh - 130px)' }}>
            <Skeleton variant="rectangular" width="100%" height="100%" />
          </Box>
          <Box
            sx={{
                backgroundColor:'#333333',
                color: '#f1f1f1',
                pt: 5,
                pb: 3,
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <Skeleton variant="rectangular" width="100%" height={80} />
          </Box>
        </>
      ) : (
        <>
          <Header />
          <Navbar />
          {children}
          <Footer />
        </>
      )}
    </Fragment>
  );
};

export default connect(null, { checkAuthenticated, load_investor_profile })(Layout);