import {
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT_SUCCESS,
    LOGOUT_FAIL,
    AUTHENTICATED_SUCCESS,
    AUTHENTICATED_FAIL,
    DELETE_ACCOUNT_FAIL,
    DELETE_ACCOUNT_SUCCESS
} from '../actions/types';

const initialState = {
    user: null,
    isAuthenticated: null, // Initially, set to `null` to signify undetermined state.
    error: null,
};

const auth = function(state = initialState, action) {

    switch(action.type) {
        case AUTHENTICATED_SUCCESS:
            return {
                ...state,
                isAuthenticated: true,
                user: action.payload.user || state.user // Use payload user if provided
            };

        case AUTHENTICATED_FAIL:
            return {
                ...state,
                isAuthenticated: false,
                user: null
            };

        case REGISTER_SUCCESS:
        case LOGIN_SUCCESS:
            return {
                ...state,
                isAuthenticated: true,
                user: action.payload.user, // Set user data on successful login
                error: null // Clear errors on success
            };

        case LOGOUT_SUCCESS:
            return {
                ...state,
                isAuthenticated: false,
                user: null // Clear user data on logout
            };

        case REGISTER_FAIL:
        case LOGIN_FAIL:
            return {
                ...state,
                isAuthenticated: false,
                user: null,
                error: action.payload // Store error message
            };

        case DELETE_ACCOUNT_SUCCESS:
            return {
                ...state,
                isAuthenticated: false,
                user: null,
                error: null // Clear errors on successful deletion
            };

        case DELETE_ACCOUNT_FAIL:
        case LOGOUT_FAIL:
            return {
                ...state,
                error: action.payload // Keep error for failed actions
            };

        default:
            return state;
    }
};

export default auth;
