import React from 'react';
import { Button } from '@mui/material';
import images from '../../images';

const GoogleSignUpButton = () => (
    <Button
        variant="bright"
        color="secondary"
        startIcon={<img src={images.google} alt="Google Icon" />}
        style={{ marginTop: '30px' }}
        fullWidth
    >
        Sign up with Google
    </Button>
);

export default GoogleSignUpButton;
