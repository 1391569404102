import React from 'react';
import StepRenderer from './StepRenderer';

const StepContainer = ({key, step, formData, setFormData, isAccepted, setIsAccepted}) => {
    return (
        <>
            {step && (

                    <StepRenderer
                    step={step}
                    formData={formData}
                    setFormData={setFormData}
                    isAccepted={isAccepted}
                    setIsAccepted={setIsAccepted}
                    isRequired={true} // All fields are required
                    
                    />

            )}
        </>
    )
}

export default StepContainer;