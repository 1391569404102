import React from 'react';
import { Grid, TextField } from '@mui/material';

const SearchBar = ({ searchQuery, setSearchQuery }) => {
    return (
        <Grid item xs={12} sm={3} style={{ padding: '20px' }}>
            <Grid container justifyContent="flex-end"> {/* Make this a container */}
                <TextField
                    variant="outlined"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    placeholder="Search by name..."
                    size="small"
                />
            </Grid>
        </Grid>
    );
};

export default SearchBar;
