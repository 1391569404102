import React from 'react';
import { differenceInDays } from 'date-fns';

const DaysToClose = ({ closingAt }) => {
    const closingDate = new Date(closingAt);
    const currentDate = new Date();
    const daysRemaining = differenceInDays(closingDate, currentDate);

    return <span>{daysRemaining} days</span>;
};

export default DaysToClose;
