import React, { useState, useEffect } from 'react';
import { FormControl, InputLabel, Select, MenuItem, Grid } from '@mui/material';
import api from '../../axiosInstance';
import Loading from './Loading';

// FilterComponent for standard filters
const FilterComponent = ({ label, value, onChange, options }) => (
    <FormControl style={{ padding: '5px', minWidth: '100px', height: '40px' }}>
        <InputLabel style={{ fontSize: '0.75rem' }}>{label}</InputLabel>
        <Select 
            value={value} 
            onChange={(e) => onChange(e.target.value)} 
            style={{ height: '40px', fontSize: '0.8rem' }}>
            {options.map(option => (
                <MenuItem key={option.value} value={option.value} style={{ fontSize: '0.8rem' }}>
                    {option.label}
                </MenuItem>
            ))}
        </Select>
    </FormControl>
);

// Main Filter that fetches options from the API
export const Filter = ({ label, value, onChange, fetchOptionsUrl }) => {
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchOptions = async () => {
            setLoading(true);
            try {
                const response = await api.get(fetchOptionsUrl);
                setOptions(response.data);
            } catch (error) {
                console.error('Error fetching options:', error);
                setError('Error fetching options. Please try again later.');
            } finally {
                setLoading(false);
            }
        };

        fetchOptions();
    }, [fetchOptionsUrl]);

    if (loading) return <div><Loading /></div>;
    if (error) return <Typography>{error}</Typography>

    return <FilterComponent label={label} value={value} onChange={onChange} options={options} />;
};

// Numeric Filter Component
const NumericFilterComponent = ({ label, selectedValue, onChange, options }) => (
    <FilterComponent label={label} value={selectedValue} onChange={onChange} options={options} />
);

// Generate numeric options for filtering
const generateNumericOptions = (limit1, limit2, labels) => ([
    { value: `ALL`, label: labels.all },
    { value: `less_than_${limit1}`, label: labels.lessThan },
    { value: `between_${limit1}_and_${limit2}`, label: labels.between },
    { value: `more_than_${limit2}`, label: labels.moreThan }
]);

// NumericFilter for a specific numeric range
export const NumericFilter = ({ label, limit1, limit2, selectedValue, onChange }) => {
    const options = generateNumericOptions(limit1, limit2, { all: 'All', lessThan: `< ${limit1}`, between: `${limit1}-${limit2}`, moreThan: `+${limit2}` });
    return <NumericFilterComponent label={label} selectedValue={selectedValue} onChange={onChange} options={options} />;
};

// Valuation-specific Numeric Filter
export const NumericFilterForValuation = ({ label, limit1, limit2, selectedValue, onChange }) => {
    const options = generateNumericOptions(limit1, limit2, { all: 'All', lessThan: 'Startup (<10 M)', between: 'Scale-up (10M - 1B)', moreThan: 'Unicorn (1B+)' });
    return <NumericFilterComponent label={label} selectedValue={selectedValue} onChange={onChange} options={options} />;
};

// CombinedFilters Component
const CombinedFilters = ({ 
    dealStatusValue, 
    onDealStatusChange, 
    valuationValue, 
    onValuationChange, 
    sectorValue, 
    onSectorChange 
}) => (
    <Grid item xs={12} sm={6} padding='20px'> 
        <Grid container spacing={2}>
            {/* Deal Status Filter */}
            <Grid item xs={12} sm={4}>  
                <Filter 
                    label="Deal Status" 
                    value={dealStatusValue} 
                    onChange={onDealStatusChange} 
                    fetchOptionsUrl="/api/products/companies/filter-options/deal_status/" 
                />
            </Grid>

            {/* Valuation Filter */}
            <Grid item xs={12} sm={4}>  
                <NumericFilterForValuation 
                    label="Valuation Range" 
                    limit1={10000000} 
                    limit2={1000000000} 
                    selectedValue={valuationValue} 
                    onChange={onValuationChange} 
                />
            </Grid>

            {/* Sector Filter */}
            <Grid item xs={12} sm={4}>  
                <Filter 
                    label="Sector" 
                    value={sectorValue} 
                    onChange={onSectorChange} 
                    fetchOptionsUrl="/api/products/companies/filter-options/sector/" 
                />
            </Grid>
        </Grid>
    </Grid>
);

export default CombinedFilters;
