export const TABS = {
    REGULAR: [
      { label: 'Reservation Details', value: '1' },
      { label: 'Accept Terms', value: '2' },
      { label: 'Review', value: '3' },
      { label: 'Payment', value: '4' }
    ],
    WAITING_LIST: [
      { label: 'Reservation Details', value: '1' },
      { label: 'Review and Submit', value: '2' }
    ]
  };