import React from 'react';
import { LinearProgress } from '@mui/material';

const FormProgressBar = ({ currentStep, totalSteps }) => {
    return (
        <LinearProgress
            variant="determinate"
            color="secondary"
            value={totalSteps > 0 ? (currentStep / totalSteps) * 100 : 0}
            sx={{ marginBottom: 1 }}
        />
    );
};

export default FormProgressBar;
