import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Grid } from '@mui/material';
import NumberFormatter from './helpers/NumberFormatter';
import DaysToClose from './helpers/DaysToClose';
import formatValuation from '../components/helpers/FormatValuation';
import AvToTotal from './helpers/AvToTotal';
import DealStatusButton from './DealStatusButton';

const LiveDeals = ({company, deals }) => (
    <Grid>

        <Typography variant="h4" padding='20px'>Live Deals</Typography>

        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Deal Type</TableCell>
                        <TableCell>Share Class</TableCell>
                        <TableCell>Share Price</TableCell>
                        <TableCell>Valuation</TableCell>
                        <TableCell>Available Shares</TableCell>
                        <TableCell>% to Last Valuation</TableCell>
                        <TableCell>Minimum Investment</TableCell>
                        <TableCell>Time to close</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {deals.length === 0 ? (
                        <TableRow>
                            <TableCell colSpan={8} align="center">
                                <Grid container sx={{flexDirection: 'column'}}>
                                    <Grid>
                                    No deals available
                                    </Grid>
                                    <Grid>
                                    <DealStatusButton status={company.deal_status} company={company}/>
                                    </Grid>
                                </Grid>

                                </TableCell>
                            
                        </TableRow>
                    ) : (
                        deals.map((deal) => (
                            <TableRow key={deal.id}>
                                <TableCell>{deal.type}</TableCell>
                                <TableCell>{deal.share_class}</TableCell>
                                <TableCell>${deal.implied_share_price}</TableCell>
                                <TableCell>${formatValuation(deal.implied_valuation)}</TableCell>
                                <TableCell><AvToTotal current={deal.available_shares} total={deal.number_of_shares} /></TableCell>
                                <TableCell>{deal.discount_to_last_val}%</TableCell>
                                <TableCell>$<NumberFormatter number={deal.deal_min_share_count * deal.implied_share_price} /></TableCell>
                                <TableCell><DaysToClose closingAt={deal.closing_at} /></TableCell>
                                <TableCell>
                                    <DealStatusButton status={company.deal_status} company={company} dealId={deal.id}/>
                                </TableCell>
                            </TableRow>
                        ))
                    )}
                </TableBody>
            </Table>
        </TableContainer>

    </Grid>
);

export default LiveDeals;
