// OrderFormBase.js
import React from 'react';
import { Grid, Box, Paper, Alert, CircularProgress, Tab } from '@mui/material';
import { TabContext, TabList } from '@mui/lab';
import { OrderTypes } from '../constants/sharedTypes';
import { TABS } from '../constants/sharedConstants';



const OrderFormBase = ({
  type,
  loading,
  error,
  validationError,
  tabValue,
  onTabChange,
  children,
  renderButtons,
  showWaitingListAlert = false
}) => {
  const tabs = type === OrderTypes.REGULAR ? TABS.REGULAR : TABS.WAITING_LIST;

  if (loading) {
    return (
      <Grid container justifyContent="center" alignItems="center" sx={{ height: '100vh' }}>
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <Grid container justifyContent="center" style={{ maxWidth: '80%', margin: '0 auto', padding: '40px' }}>
      <Box component={Paper} style={{ width: '100%', padding: '60px', marginTop: '60px' }}>
        {showWaitingListAlert && (
          <Alert severity="warning" sx={{ mb: 3 }}>
            The deal you are interested in is currently oversubscribed. Please submit below your info and you will be placed on a waiting list.
          </Alert>
        )}

        {validationError && (
          <Alert severity="warning" sx={{ mb: 3 }}>
            {validationError}
          </Alert>
        )}

        <form>
          <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={onTabChange} aria-label="order form tabs">
                {tabs.map(tab => (
                  <Tab key={tab.value} label={tab.label} value={tab.value} />
                ))}
              </TabList>
            </Box>
            
            {children}
            <Grid container spacing={2} sx={{ marginTop: 2, justifyContent: 'center', alignItems: 'center' }}>
          {renderButtons && renderButtons()}
        </Grid>
          </TabContext>
        </form>

      
      </Box>
    </Grid>
  );
};


export default OrderFormBase;