import { createTheme } from '@mui/material/styles';

const primaryColor = '#5acbf4';
const primaryDark = '#3c99c4';
const primaryLight = '#7bdaf4';
const primaryDarker = '#3e98bf';

const secondaryColor = '#8cca6f';
const secondaryLight = '#e6f5d7';
const secondaryDark = '#266628';
const secondaryDarker = '#6ea454';


const textPrimary = '#2e2e2e';
const textSecondary = '#4d4d4d';
const textVeryLight = '#F1F1F1';

const defaultBackground = '#f4f9fb';
const paperBackground = '#fafaf5';
const darkBackground = '#333333';
const greyBackground = '#e6e6e6';

const shadowsBasic = '0px 1px 3px rgba(0, 0, 0, 0.12), 0px 1px 2px rgba(0, 0, 0, 0.24)';


const theme = createTheme({
    palette: {
        primary: {
            main: primaryColor,
            dark: primaryDark,
            light: primaryLight,
        },
        secondary: {
            main: secondaryColor,
            light: secondaryLight,
            dark: secondaryDark,

        },
        text: {
            primary: textPrimary,
            secondary: textSecondary,
            light: textVeryLight
        },
        background:
        {
            default: defaultBackground,
            paper: paperBackground,
            dark: darkBackground,
            grey: greyBackground
        }

    },
    typography: {
        "fontFamily": `"Poppins", "Roboto", "Helvetica", "Arial", sans-serif`,
        "fontSize": 14,
        "fontWeightLight": 300,
        "fontWeightRegular": 400,
        "fontWeightMedium": 500,
        darkBg: {
            color: textVeryLight,
            '&:hover': {
                color: secondaryColor,
            },
            textDecoration: "None",
            fontFamily: "Poppins",
            padding: '5px'
        },
        classic: {
            color: textPrimary,
            textDecoration: "None",
            fontFamily: "Poppins",
            flexShrink: '0',
            padding: '40px',
            textAlign: 'justify'
        },

        header: {
            fontSize: '34px',
            lineHeight: '1.235',
            letterSpacing: '0.25px',
            fontFamily: `"Poppins", "Roboto", "Helvetica", "Arial", sans-serif`, // Customizing to use Poppins first
            fontWeight: 'bold'

        },
        link: {
            color: secondaryColor,
            '&:hover': {
                color: secondaryDark,
            },
            fontFamily: "Poppins",
            flexShrink: '0',
            padding: '30px'
        },
        note: {
            color: textSecondary,
            fontFamily: "Poppins",
            flexShrink: '0',
            backgroundColor: greyBackground,
            padding: '30px',
            margin: '0px',
            display: 'inline-block',
            borderRadius: '4px'
        }
    },
    components: {
        MuiPaper: {
            variants: [
                {
                    props: { variant: 'default' },
                    style: {
                        backgroundColor: defaultBackground, // default background
                        padding: '20px',
                        marginBottom: '20px',
                        boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.1), 0px 6px 6px rgba(0, 0, 0, 0.2)', // add stronger shadow
                    },
                },
                {
                    props: { variant: 'paper' },
                    style: {
                        backgroundColor: paperBackground, // paper background
                        padding: '20px',
                        marginBottom: '20px',
                        boxShadow: '0px 12px 24px rgba(0, 0, 0, 0.2), 0px 8px 8px rgba(0, 0, 0, 0.3)', // add stronger shadow
                    },
                },
                {
                    props: { variant: 'dark' },
                    style: {
                        backgroundColor: darkBackground, // dark background
                        padding: '20px',
                        marginBottom: '20px',
                        boxShadow: '0px 14px 28px rgba(0, 0, 0, 0.3), 0px 10px 10px rgba(0, 0, 0, 0.4)', // add stronger shadow
                    },
                },
                {
                    props: { variant: 'primary' },
                    style: {
                        backgroundColor: primaryColor, // primary color background
                        padding: '20px',
                        marginBottom: '20px',
                        boxShadow: '0px 16px 32px rgba(90, 203, 244, 0.5), 0px 12px 12px rgba(90, 203, 244, 0.6)', // add stronger shadow
                    },
                },
            ],
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: '8px', // custom border radius for all buttons
                    padding: '10px 20px', // custom padding for all buttons
                    margin: '10px',

                },
                brightPrimary: {
                    backgroundColor: '#ffffff', // Bright color background
                    color: primaryColor, // Text color using primary color
                    boxShadow: shadowsBasic,
                    '&:hover': {
                        backgroundColor: primaryColor,
                        color: 'white' // Lighter shade on hover
                    },
                },
                brightSecondary: {
                    backgroundColor: '#ffffff', // Bright color background
                    color: secondaryColor, // Text color using primary color
                    boxShadow: shadowsBasic,
                    '&:hover': {
                        backgroundColor: secondaryColor,
                        color: 'white'
                    }
                },
                containedPrimary: {
                    backgroundColor: primaryColor,
                    color: textVeryLight,
                    '&:hover': {
                        backgroundColor: 'white',
                        color: primaryColor // color on hover for contained primary buttons
                    },
                },
                containedSecondary: {
                    backgroundColor: secondaryColor,
                    color: textVeryLight,
                    '&:hover': {
                        backgroundColor: 'white',
                        color: secondaryColor
                        // color on hover for contained secondary buttons
                    },
                },
                outlinedPrimary: {
                    borderColor: primaryColor,
                    '&:hover': {
                        borderColor: primaryDarker,
                        backgroundColor: 'rgba(25, 118, 210, 0.08)', // slight background color on hover for outlined primary buttons
                    },
                },
                outlinedSecondary: {
                    borderColor: secondaryColor,
                    '&:hover': {
                        borderColor: secondaryDarker,
                        backgroundColor: 'rgba(220, 0, 78, 0.08)', // slight background color on hover for outlined secondary buttons
                    },
                },
                textPrimary: {
                    color: primaryDark,
                    '&:hover': {
                        backgroundColor: primaryLight, // slight background color on hover for text primary buttons
                    },
                },
                textSecondary: {
                    color: secondaryDark,
                    '&:hover': {
                        backgroundColor: secondaryLight, // slight background color on hover for text secondary buttons
                    },
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                variants: [
                    {
                        props: { variant: 'secondary' },
                        style: {
                            color: secondaryColor
                        }
                    }
                ]
            }
        },

    MuiGrid: {
        root:
        {
            backgroundColor: defaultBackground

        },
        variants: [
            {
                props: { variant: 'primary' },
                style: {
                    backgroundColor: primaryColor,
                    color: textVeryLight

                }
            },
            {
                props: { variant: 'default' },
                style: {
                    backgroundColor: defaultBackground,
                    color: textPrimary

                }
            },
            {
                props: { variant: 'paper' },
                style: {
                    backgroundColor: paperBackground,
                    color: textPrimary
                }
            }

        ]

    },
    MuiColumn: {
        root:
        {


        },
        variants: [
            {
                props: { variant: 'secondary' },
                style: {
                    backgroundColor: secondaryColor,
                    color: textVeryLight,
                    borderRadius: '10px',
                    height: '500px',
                    width: '45%',




                }
            },
            {
                props: { variant: 'grey' },
                style: {
                    backgroundColor: greyBackground,
                    color: textPrimary,
                    borderRadius: '10px',
                    height: '500px',
                    width: '45%',


                }
            },

        ]

    },
    MuiIconBox: {
        root:
        {


        },
        variants: [
            {
                props: { variant: 'secondary' },
                style: {
                    backgroundColor: secondaryColor,
                    color: textVeryLight

                }
            },
            {
                props: { variant: 'light' },
                style: {
                    backgroundColor: textVeryLight,
                    color: secondaryColor,



                }
            },

        ]

    }
},

    breakpoints: {
    values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
    },
},
    shape: {
    borderRadius: 8,
},
    shadows: [
    'none',
    '0px 1px 3px rgba(0, 0, 0, 0.12), 0px 1px 2px rgba(0, 0, 0, 0.24)',  // elevation 1
    '0px 3px 6px rgba(0, 0, 0, 0.16), 0px 3px 6px rgba(0, 0, 0, 0.23)',  // elevation 2
    '0px 10px 20px rgba(0, 0, 0, 0.19), 0px 6px 6px rgba(0, 0, 0, 0.23)',  // elevation 3
    '0px 14px 28px rgba(0, 0, 0, 0.25), 0px 10px 10px rgba(0, 0, 0, 0.22)',  // elevation 4
    '0px 18px 36px rgba(0, 0, 0, 0.30), 0px 15px 15px rgba(0, 0, 0, 0.22)',  // elevation 5
    '0px 24px 48px rgba(0, 0, 0, 0.35), 0px 20px 20px rgba(0, 0, 0, 0.22)',  // elevation 6
    '0px 30px 60px rgba(0, 0, 0, 0.40), 0px 25px 25px rgba(0, 0, 0, 0.22)',  // elevation 7
    '0px 36px 72px rgba(0, 0, 0, 0.45), 0px 30px 30px rgba(0, 0, 0, 0.22)',  // elevation 8
],

});


export default theme;
