import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { 
  Button, 
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogContentText, 
  DialogTitle,
  Box
} from '@mui/material';import { TabPanel} from '@mui/lab';
import CSRFToken from '../components/general/CSRFToken';
import ReservationDetails from '../components/order/ReservationDetails';
import ReviewOrder from '../components/order/ReviewOrder';
import { useOrderData } from '../hooks/useOrderData';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { OrderTypes } from '../constants/sharedTypes';
import OrderFormBase from '../components/OrderFormBase';
import { useDispatch, useSelector } from 'react-redux';
import { 
  updateDealData, 
  updateCompanyData, 
  updateOrderData,
  handleWaitingListSignUp 
} from '../actions/order'; 


const WaitingListForm = () => {
  const { dealId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  const [tabValue, setTabValue] = useState('1');
  const [validationError, setValidationError] = useState('');

  const { deal, company, loading, error } = useOrderData(dealId);
  
  const formData = useSelector(state => state.order.formData);
  const waitingListError = useSelector(state => state.order.waitingListError);
  const isLoading = useSelector(state => state.order.loading);
  const waitingListOrder = useSelector(state => state.order.waitingListOrder);


  useEffect(() => {
    if (deal) {
      dispatch(updateDealData(deal));
      
      const initialFormData = {
        reservation_amount: deal.minimum_investment_size,
        share_price: deal.implied_share_price,
        equivalent_number_of_shares: Math.round(
          (0.95 * deal.minimum_investment_size) / deal.implied_share_price
        ),
        final_reservation_amount: 0.95 * deal.minimum_investment_size,
      };
      
      dispatch(updateOrderData(initialFormData));
    }
    
    if (company) {
      dispatch(updateCompanyData(company));
    }
  }, [deal, company, dispatch]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    let newFormData = { ...formData };

    if (name === 'reservation_amount') {
      const finalReservationAmount = 0.95 * value;
      newFormData = {
        ...newFormData,
        [name]: value,
        equivalent_number_of_shares: Math.round(finalReservationAmount / formData.share_price),
        final_reservation_amount: finalReservationAmount,
      };
    } else {
      newFormData = {
        ...newFormData,
        [name]: value,
      };
    }

    dispatch(updateOrderData(newFormData));
  };

  const handleNavigate = (path) => {
    navigate(path);
  };
  const handleSubmit = async () => {
    try {
      const result = await dispatch(handleWaitingListSignUp(dealId, formData));
      if (result?.type?.includes('SUCCESS')) {
        setValidationError('');
      }
    } catch (error) {
      setValidationError(error.message || 'Failed to place order. Please try again.');
    }
  };

  const handleNext = () => {
    setTabValue((parseInt(tabValue) + 1).toString());
  };

  const handleBack = () => {
    setTabValue((parseInt(tabValue) - 1).toString());
  };

  const renderButtons = () => {
    if (tabValue === '1') {
      return (
        <Button variant="contained" color="primary" onClick={handleNext}>
          Next
        </Button>
      );
    } else if (tabValue === '2') {
      return (
        <>
          <Button variant="contained" onClick={handleBack}>
            Back
          </Button>
          <Button 
            variant="contained" 
            color="secondary" 
            onClick={handleSubmit}
          >
            Join Waiting List
          </Button>
        </>
      );
    }
    return null;
  };

  // Only render content when we have all required data
  const canRenderContent = !loading && !error && deal && company;

  const content = canRenderContent ? (
    <>
      <TabPanel value="1">
        <ReservationDetails
          
        />
      </TabPanel>
      <TabPanel value="2">
        <ReviewOrder
          formData={formData}
        />
      </TabPanel>
    </>
  ) : null;

  return (

    <>
     <OrderFormBase
      type={OrderTypes.WAITING_LIST}
      loading={loading || isLoading}
      error={error || waitingListError}
      validationError={validationError}
      tabValue={tabValue}
      onTabChange={(e, value) => setTabValue(value)}
      renderButtons={canRenderContent ? renderButtons : undefined}
      showWaitingListAlert={true}
    >
      <CSRFToken />
      {content}
    </OrderFormBase>

    {waitingListOrder && (
    <Dialog 
        open={!!waitingListOrder} 
        maxWidth="sm" 
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: '16px',
            padding: '16px'
          }
        }}
      >
        <DialogTitle sx={{ 
          textAlign: 'center',
          paddingBottom: 0
        }}>
          <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center',
            gap: 2
          }}>
            <CheckCircleOutlineIcon 
              color="success" 
              sx={{ fontSize: 64 }} 
            />
            Order Placed Successfully
          </Box>
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ 
            textAlign: 'center',
            marginTop: 2,
            marginBottom: 2
          }}>
            Your Order has been placed on a waiting list. You will be notified by email if new shares become available.
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ 
          justifyContent: 'center',
          padding: '24px',
          gap: 2
        }}>
          <Button 
            variant="contained" 
            color="primary" 
            onClick={() => handleNavigate('/portfolio')}
          >
            Go to Your Portfolio
          </Button>
          <Button 
            variant="contained" 
            color="primary" 
            onClick={() => handleNavigate('/investments')}
          >
            Invest More
          </Button>
        </DialogActions>
      </Dialog>)};
    </>
   
  );
};

export default WaitingListForm;