import React from 'react';
import Snackbar from '@mui/material/Snackbar';

const FormSnackbar = ({ open, onClose, message }) => {
    return (
        <Snackbar
            open={open}
            onClose={onClose}
            message={message}
            autoHideDuration={6000}
        />
    );
};

export default FormSnackbar;
