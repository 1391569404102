import React, { useEffect, useState, useCallback } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from '@stripe/react-stripe-js';
import { Box, Button, Alert } from '@mui/material';
import Loading from '../general/Loading';
import api from '../../axiosInstance';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';

const stripePromise = loadStripe('pk_test_51KlGsICFu7uROflVwYpmFGj8Xr9KVwJr7c6ZJt89PinNyUMHyM4OHjgZACCw3tuCQz5wp253LR9C7ypw5MTHSRNI00GR6kClfJ');

const CheckoutForm = ({ orderId, formData }) => {

  const [clientSecret, setClientSecret] = useState(null);
  const [sessionId, setSessionId] = useState(null);
  const [hasPaid, setHasPaid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  console.log('hello');


  const sendConfirmationEmail = useCallback(async () => {
    const config = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRFToken': Cookies.get('csrftoken')
      }
    };

    const body = {
      email_type: "purchase_confirmation",
      recipient_email: "zuzanna.onderko@gmail.com",
      context: {
        user_name: "John Doe",
        order_id: orderId,
      }
    };

    try {
      await api.post('/api/comm/email/send/purchase_confirmation/', body, config);
    } catch (error) {
      console.error('There was a problem with the API call:', error);
    }
  }, [orderId]);


  const updateOrderToPaid = useCallback(async () => {
    const config = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRFToken': Cookies.get('csrftoken'),
      },
    };
    try {
      const response = await api.put(`/api/orders/update_status/${orderId}/`, { status: '4' }, config);
      if (response.status !== 200 && response.status !== 201) {
        throw new Error('Failed to update order status');
      }
    } catch (error) {
      setError(error.message);
    }
  }, [orderId]); // Add dependencies to the useCallback hook



  // Create the checkout session when formData is ready
  useEffect(() => {
    const createCheckoutSession = async () => {
      setLoading(true);
      try {

       const body= JSON.stringify({
          price: "price_1Ps3UQCFu7uROflV4KmutHzj",
          quantity: formData.equivalent_number_of_shares,
        })

        const config = {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRFToken': Cookies.get('csrftoken'),
          },
        };

        const response = await api.post('/api/orders/create_checkout_session/', body, config);
        const data = response.data

        if (response.status === 200 | response.status===201) {

          setClientSecret(data.clientSecret);
          setSessionId(data.id);
        } else {
          throw new Error(data.message || 'Failed to create checkout session');
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    if (formData) createCheckoutSession();
  }, [formData]);



  // Check session status
  useEffect(() => {
    const checkSessionStatus = async () => {
      if (sessionId && !hasPaid) {


        const config = {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRFToken': Cookies.get('csrftoken'),
          },
        };

        const response = await api.get(`/api/orders/checkout_session_status/${sessionId}/`, config)
        const data = await response.data

        if (data.status === 'paid') {
          setHasPaid(true);
          updateOrderToPaid();
          sendConfirmationEmail();
        } else {
          setTimeout(checkSessionStatus, 5000); // Re-check after 5 seconds
        }
      }
    };

    checkSessionStatus();
  }, [sessionId, hasPaid, updateOrderToPaid, sendConfirmationEmail]);


  // Loading state
  if (loading) return <Loading />;

  // Error handling
  if (error) return <Alert severity="error">{error}</Alert>;


  return (
    <div id="checkout">
      {!hasPaid && clientSecret && (
        <EmbeddedCheckoutProvider stripe={stripePromise} options={{ clientSecret }}>
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      )}
      {hasPaid && (
        <Box>
          <Alert severity="success">
            Your Order Has Been Reserved. You will receive all the required documentation by email.
          </Alert>

          <Button variant="contained" color="primary" onClick={() => navigate('/portfolio')}>
            Go to Your Portfolio
          </Button>
          <Button variant="contained" color="primary" onClick={() => navigate('/investments')}>
            Invest More
          </Button>
        </Box>
      )}
    </div>
  );
};

export default CheckoutForm;