import React from 'react';
import { Box, Alert, FormControlLabel, Checkbox, Typography, Grid } from '@mui/material';
import Rulebook from '../../pages/RuleBook';
import { useSelector, useDispatch } from 'react-redux';
import { updateOrderData } from '../../actions/order'; // Import the action

const AcceptTerms = () => {
  const dispatch = useDispatch();
  const formData = useSelector((state) => state.order.formData);
  const deal = useSelector((state) => state.order.dealData);



  const handleChange = (event) => {
    const { name, checked } = event.target;

    const updatedFormData = {
      ...formData,
      [name]: checked,
    };

    dispatch(updateOrderData(updatedFormData));
  };
  return (
    <Grid container>

      {/* Warning Alert */}
      <Box sx={{ display: 'flex', justifyContent: 'center', paddingTop: '20px' }}>
        <Alert severity="warning">Please scroll to read the agreement</Alert>
      </Box>
      {/* Scrollable Rulebook(s) */}
      <Grid container flexDirection="row" spacing={2} padding={2}>
        {/* First Rulebook */}
        <Grid item xs={12} md={12} padding={2} alignContent={'center'}>
          <Typography variant="h6" sx={{ marginBottom: 2}} >
            TermSheet
          </Typography>
          <Box
            sx={{
              height: '50vh',
              overflowY: 'auto',
              padding: '20px',
              display: 'flex',
              justifyContent: 'center',
              gap: 2, // spacing between two rulebooks
            }}
          >
            <Rulebook title="TermSheet" sx={{ color: 'white', padding:'10px' }} />
          </Box>
        </Grid>

      
      </Grid>


      <Grid flexDirection={'column'} alignItems={'center'}>


      {/* Checkbox for agreement */}
      <Box sx={{ display: 'flex', justifyContent: 'center', paddingTop: '20px' }}>
        <FormControlLabel
          control={
            <Checkbox
              boolean={formData.term_sheet_acceptance_1}
              onChange={handleChange}
              name="term_sheet_acceptance_1"
            />
          }
          label={
            <Typography component="span">
              I confirm I have read and agree to the terms
              <Typography component="span" color="error" sx={{ ml: 0.5 }}>
                *
              </Typography>
            </Typography>
          }
        />
      </Box>
        </Grid>
    </Grid>
  );
};

export default AcceptTerms;
