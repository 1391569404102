import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import '../App.css';

const DataGridComponent = ({ rows, columns, isAuthenticated, onRowClick }) => {
    const modifiedColumns = columns.map(col => {
        if (col.field !== 'logo' && col.field !== 'name' && !isAuthenticated) {
            return {
                ...col,
                renderCell: params => <div className="blur">{params.value}</div>,
            };
        }
        return col;
    });


    const getRowClassName = (params) => {
        const status = params.row.deal_status;
        switch (status) {
            case 'OVERSUBSCRIBED':
                return 'row-waiting-list';
            case 'COMING_SOON':
                return 'row-coming-soon';
            case 'INVEST_NOW':
                return 'row-invest-now';
            default:
                return 'white';
        }
    };



    return (
        <DataGrid
            rows={rows}
            columns={modifiedColumns}
            rowHeight={70}
            initialState={{ pagination: { paginationModel: { pageSize: 5 } } }}
            pageSizeOptions={[5]}
            disableColumnMenu
            onRowClick={onRowClick}
            getRowClassName={getRowClassName}
            sx={{
                '& .MuiDataGrid-cell': {
                    fontSize: '0.8rem',
                },
                '& .MuiDataGrid-columnHeader': {
                    fontSize: '0.9rem', 
                },
                '& .MuiDataGrid-row:hover': {
                    backgroundColor: '#91cfe6', // Change the hover color
                },
                margin: '20px'
            }}
        />
    );
};

export default DataGridComponent;
