
const getDisplayCurrency = (currency) => {
    const currencyMap = {
        EUR: '🇪🇺 EUR',     // European Union flag for Euro
        GBP: '🇬🇧 GBP', // United Kingdom flag for GBP
        USD: '🇺🇸 USD', // United States flag for USD
    };

    // Emoji representations for generic currency
    const currencyEmoji = '💱'; // Currency exchange emoji

    return currencyMap[currency] || `${currencyEmoji} ${currency}`; // Fallback for any other currency
};


export default getDisplayCurrency;
