import React from 'react';
import { Box, Card, CardActionArea, CardContent, Typography } from '@mui/material';
import { useTheme } from '@mui/material';

const ChoiceStep = ({ step, formData, setFormData }) => {
    const theme = useTheme();

    const handleChoiceChange = (questionId, choiceId) => {
        setFormData((prevData) => ({
            ...prevData,
            [questionId]: choiceId,
        }));
    };

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            alignItems: 'center',
            padding: { xs: 2, md: 4 }, // Responsive padding
            overflow: 'hidden', // Prevent overflow
        }}>
            <Typography variant="h6" gutterBottom sx={{
                    fontSize: { xs: '1rem', md: '1.75rem' },
                    textAlign: 'center'
                }}>
                {step.question.question} <span style={{ color: 'red' }}>*</span> {/* Required indicator */}
            </Typography>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', sm: 'row' }, // Stack on small screens, row on larger
                    flexWrap: 'wrap', // Allow cards to wrap onto the next line
                    gap: 2,
                    width: '100%', // Use full width of the container
                    justifyContent: 'center', // Center choices horizontally
                }}
            >
                {step.question.choices.map((choice) => (
                    <Card
                        key={choice.id}
                        onClick={() => handleChoiceChange(step.question.id, choice.id)}
                        sx={{
                            backgroundColor: formData[step.question.id] === choice.id ? theme.palette.secondary.light : 'white',
                            width: { xs: '100%', sm: 'calc(50% - 16px)', md: 'calc(33% - 16px)' }, // Responsive widths
                            minWidth: '120px', // Minimum width to prevent narrow cards
                            maxWidth: '300px', // Adjust maxWidth to limit size on larger screens
                            boxShadow: 2, // Add shadow for better visual separation
                            overflow: 'visible', // Prevent card overflow
                        }}
                    >
                        <CardActionArea>
                            <CardContent>
                                <Typography
                                    variant="body1"
                                    sx={{
                                        fontSize: { xs: '0.8rem', md: '1.25rem' }, // Responsive font size for choice
                                        textAlign: 'center', // Center the text
                                    }}
                                    noWrap={false} // Allow wrapping of the text
                                >
                                    {choice.name}
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                ))}
            </Box>
        </Box>
    );
};

export default ChoiceStep;
