import * as React from 'react';
import { LineChart as MuiLineChart } from '@mui/x-charts/LineChart';
import { Typography, Box, Paper } from '@mui/material';
import { useTheme } from '@mui/material';

const LineChart = ({ title, years, values, color }) => {
    const theme = useTheme();

    return (
        <Box component={Paper} style={{ padding: '40px'}}>
            <Typography variant='h5'>{title}</Typography>

            <MuiLineChart
                xAxis={[{
                    data: years,
                    scaleType: 'time',
                    valueFormatter: (date) => date.getFullYear().toString(),
                    
                }]}
                series={[{
                    data: values,
                    area: true,
                    color: color || theme.palette.primary.light,
                }]}
                width={600}
                height={300}
                grid={{ vertical: false, horizontal: true }}
            />
        </Box>
    );
}

export default LineChart;
