// AutoCompleteStep.js
import React, { useEffect, useState } from 'react';
import { Box, Typography, TextField } from '@mui/material';
import Autocomplete from '@mui/lab/Autocomplete'; // Use MUI Autocomplete

const AutoCompleteStep = ({ step, formData, setFormData }) => {
    const [countryChoices, setCountryChoices] = useState([]);
    const [loading, setLoading] = useState(true); // State to manage loading state

    useEffect(() => {
        const fetchCountries = async () => {
            try {
                const response = await fetch('https://api.worldbank.org/v2/country?format=json&per_page=300');
                const data = await response.json();
                if (data && data[1]) {
                    const choices = data[1].filter(country => 
                        country.id && 
                        country.name && 
                        !country.id.includes(' ') && 
                        country.region && 
                        country.region.id === 'ECS' // 'ECS' indicates a country region
                    ).map(country => ({
                        id: country.id,
                        name: country.name,
                    }));
                    setCountryChoices(choices); // Update choices state
                }
            } catch (error) {
                console.error('Error fetching country data:', error);
            } finally {
                setLoading(false); // Set loading to false after fetch
            }
        };

        fetchCountries();
    }, []);

    const handleChoiceChange = (event, value) => {
        
        setFormData(prevData => ({
            ...prevData,
            [step.question.id]: value ? value.id : null, // Store selected country's id
        }));
    };

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            alignItems: 'center',
            padding: { xs: 2, md: 4 },
            overflow: 'hidden',
        }}>
            <Typography variant="h6" gutterBottom sx={{
                    fontSize: { xs: '1rem', md: '1.75rem' },
                    textAlign: 'center'
                }}>
                {step.question.question} <span style={{ color: 'red' }}>*</span> {/* Required indicator */}
            </Typography>
            <Autocomplete
                options={countryChoices}
                getOptionLabel={(option) => option.name}
                onChange={handleChoiceChange} // Handle choice change
                loading={loading} // Show loading indicator
                renderInput={(params) => (
                    <TextField {...params} label="Select Country" variant="outlined" fullWidth />
                )}
                sx={{
                    minWidth:'200px'
                }}
            />
        </Box>
    );
};

export default AutoCompleteStep;
