import React from 'react';
import { Box, Card, CardActionArea, CardContent, Typography } from '@mui/material';
import { useTheme } from '@mui/material';

const CheckStep = ({ step, formData, setFormData }) => {
    const theme = useTheme();

    const handleSelectChange = (questionId, choiceId) => {
        setFormData((prevData) => {
            const selectedChoices = prevData[questionId] || [];
            if (selectedChoices.includes(choiceId)) {
                // Remove if already selected
                return {
                    ...prevData,
                    [questionId]: selectedChoices.filter((id) => id !== choiceId),
                };
            } else {
                // Add new choice
                return {
                    ...prevData,
                    [questionId]: [...selectedChoices, choiceId],
                };
            }
        });
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'center' }}>
            <Typography
                variant="h6"
                gutterBottom
                sx={{
                    fontSize: { xs: '1rem', md: '1.75rem' },
                    textAlign: 'center'
                }}
            >
                {step.question.question} <span style={{ color: 'red' }}>*</span> {/* Required indicator */}
            </Typography>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', sm: 'row' }, // Stack on small screens, row on larger
                    gap: 2,
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexWrap: 'wrap',
                    width: '100%', // Use full width of the container
                }}
            >
                {step.question.choices.map((choice) => (
                    <Card
                        key={choice.id}
                        onClick={() => handleSelectChange(step.question.id, choice.id)}
                        sx={{
                            backgroundColor: (formData[step.question.id] || []).includes(choice.id)
                                ? theme.palette.secondary.light
                                : 'white',
                            width: { xs: '90%', sm: '45%', md: '30%' }, // Responsive widths
                            maxWidth: '200px', // Optional: Max width to limit size on larger screens
                            cursor: 'pointer', // Change cursor to pointer on hover
                            transition: 'background-color 0.3s', // Smooth transition on hover
                            '&:hover': {
                                backgroundColor: theme.palette.action.hover, // Hover effect
                            },
                        }}
                    >
                        <CardActionArea>
                            <CardContent>
                                <Typography variant="body1" sx={{ textAlign: 'center' }}>{choice.name}</Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                ))}
            </Box>
        </Box>
    );
};

export default CheckStep;
