import React from 'react';
import { Grid } from '@mui/material';
import Loading from './Loading';

const LoadingState = ({ theme }) => {
    return (
        <div style={{ padding: 20} }>
            <Grid container flexDirection="column" alignItems="center">
                <Loading />
            </Grid>
        </div>
    );
};

export default LoadingState;
