
// src/components/order/OrderForm.js
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {   Button, } from '@mui/material';
import { TabPanel } from '@mui/lab';
import { useOrderData } from '../hooks/useOrderData';
import { OrderTypes } from '../constants/sharedTypes';
import OrderFormBase from '../components/OrderFormBase';
import ReservationDetails from '../components/order/ReservationDetails';
import AcceptTerms from '../components/order/AcceptTerms';
import ReviewOrder from '../components/order/ReviewOrder';
import CheckoutForm from '../components/order/CheckoutForm';
import { createOrder, setTabValue, updateDealData, updateCompanyData } from '../actions/order';

const OrderForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { dealId } = useParams();

  // Redux state
  const formData = useSelector((state) => state.order.formData);
  const tabValue = useSelector((state) => state.order.tabValue);
  const orderId = useSelector((state) => state.order.orderId);
  const isValueValid = useSelector((state) => state.order.isValueValid);
  // Local state just for validation feedback
  const [validationError, setValidationError] = useState('');
  
  // Get data using custom hook
  const { deal, company, loading, error } = useOrderData(dealId);

  // Required fields for each tab
  const requiredFields = {
    '1': ['reservation_amount'],
    '2': ['term_sheet_acceptance_1'],
    '3': ['term_sheet_acceptance_2', 'term_sheet_acceptance_3', 'term_sheet_acceptance_4']
  };

  // Initialize deal and company data in Redux
  useEffect(() => {
    if (deal && company) {
      dispatch(updateDealData(deal));
      dispatch(updateCompanyData(company));
    }
  }, [deal, company, dispatch]);

  // Validation logic
  const isFormValid = (currentTab) => {
    const fields = requiredFields[currentTab] || [];
    return fields.every((field) => formData && formData[field]) && isValueValid;
  };

  // Form navigation handlers
  const handleOrderPlacement = () => {
    if (isFormValid(tabValue)) {
      dispatch(createOrder(dealId, formData, tabValue));
      
      setValidationError('');
    } else {
      setValidationError('Please complete all required fields before proceeding.');
    }
  };

  const handleNext = () => {
    if (isFormValid(tabValue)) {
      const nextTab = (parseInt(tabValue) + 1).toString();
      dispatch(setTabValue(nextTab));
      setValidationError('');
    } else {
      setValidationError('Please complete all required fields before proceeding.');
    }
  };

  const handleBack = () => {
    const prevTab = (parseInt(tabValue) - 1).toString();
    dispatch(setTabValue(prevTab));
    setValidationError('');
  };

  const handleNavigate = (path) => {
    navigate(path);
  };
  // Dynamic button rendering
  const renderButtons = () => {
    switch (tabValue) {
      case '1':
        return (
          <Button 
            variant="contained" 
            color="primary" 
            onClick={handleNext}
            disabled={!isFormValid(tabValue)}
          >
            Next
          </Button>
        );
      
      case '3':
        return (
          <>
            <Button variant="contained" onClick={handleBack}>
              Back
            </Button>
            <Button 
              variant="contained" 
              color="secondary" 
              onClick={handleOrderPlacement}
              disabled={!isFormValid(tabValue)}
            >
              Proceed to Payment
            </Button>
          </>
        );
      
      case '4':
        return null;
      
      default:
        return (
          <>
            <Button variant="contained" onClick={handleBack}>
              Back
            </Button>
            <Button 
              variant="contained" 
              color="primary" 
              onClick={handleNext}
              disabled={!isFormValid(tabValue)}
            >
              Next
            </Button>
          </>
        );
    }
  };

  return (
    <>

<OrderFormBase
      type={OrderTypes.REGULAR}
      loading={loading}
      error={error}
      validationError={validationError}
      tabValue={tabValue}
      onTabChange={(e, value) => dispatch(setTabValue(value))}
      renderButtons={renderButtons}
    >
      <TabPanel value="1">
        <ReservationDetails   />
      </TabPanel>
      <TabPanel value="2">
        <AcceptTerms />
      </TabPanel>
      <TabPanel value="3">
        <ReviewOrder />
      </TabPanel>
      <TabPanel value="4">
        <CheckoutForm orderId={orderId} formData={formData}/>
      </TabPanel>
    </OrderFormBase>
      
    </>
  );
};

export default OrderForm;