
const formatValuation = (value) => {
    if (value >= 1e9) {
        return `${(value / 1e9).toFixed(1)}B`; // For billions
    } else if (value >= 1e6) {
        return `${(value / 1e6).toFixed(1)}M`; // For millions
    } else if (value >= 1e3) {
        return `${(value / 1e3).toFixed(1)}K`; // For thousands
    }
    return value.toString(); // Convert to string for consistency
};

export default formatValuation;