import React from 'react';
import ReactDOM from 'react-dom/client';  // Make sure you are using this for React 18
import App from './App';  // Ensure the import path is correct
import './index.css';  // If you have global CSS, include it


const rootElement = document.getElementById('root'); // This should match the id in index.html

if (rootElement) {
    const root = ReactDOM.createRoot(rootElement);  // React 18 uses `createRoot` here
    root.render(
        <React.StrictMode>
            <App />
        </React.StrictMode>
    );
} else {
    console.error('Root element not found!');
}
