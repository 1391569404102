import React, { useState, useEffect } from 'react';
import {
    FormControl,
    InputLabel,
    OutlinedInput,
    Grid,
    Button,
    Typography,
    Select,
    MenuItem,
    Box,
} from '@mui/material';
import { connect } from 'react-redux';
import { update_investor_profile } from '../../actions/profile';
import CSRFToken from '../general/CSRFToken';
import { Check as CheckIcon, Close as CloseIcon } from '@mui/icons-material';
import {
    INVESTOR_TYPE_CHOICES,
    ACCREDITED_STATUS_CHOICES,
    INVESTMENT_HORIZON_CHOICES,
    RISK_PREFERENCE_CHOICES,
    LIQUID_WORTH_CHOICES,
    REFERRAL_SOURCE_CHOICES,
    INVESTMENT_METHOD_CHOICES,
    INVESTMENT_ANTICIPATION_CHOICES,
    TARGET_INVESTMENT_SIZE_CHOICES,
    INVESTMENT_SOLUTIONS_CHOICES,
    PAST_INVESTMENTS_CHOICES,
} from './constants';

// Configuring fields to reduce repetition
const fieldsConfig = [
    { id: 'first_name', label: 'First Name' },
    { id: 'last_name', label: 'Last Name' },
    { id: 'dob', label: 'Date of Birth' },
    { id: 'nationality', label: 'Nationality' },
    { id: 'investor_type', label: 'Investor Type', selectOptions: INVESTOR_TYPE_CHOICES },
    { id: 'accredited_investor_status', label: 'Accredited Investor Status', selectOptions: ACCREDITED_STATUS_CHOICES },
    { id: 'investment_horizon', label: 'Investment Horizon', selectOptions: INVESTMENT_HORIZON_CHOICES },
    { id: 'risk_preference', label: 'Risk Preference', selectOptions: RISK_PREFERENCE_CHOICES },
    { id: 'liquid_worth', label: 'Liquid Worth', selectOptions: LIQUID_WORTH_CHOICES },
    { id: 'referral_source', label: 'Referral Source', selectOptions: REFERRAL_SOURCE_CHOICES },
    { id: 'investment_method', label: 'Investment Method', selectOptions: INVESTMENT_METHOD_CHOICES },
    { id: 'investing_anticipation', label: 'Investing Anticipation', selectOptions: INVESTMENT_ANTICIPATION_CHOICES },
    { id: 'target_investment_per_investment', label: 'Target Investment Per Investment', selectOptions: TARGET_INVESTMENT_SIZE_CHOICES },
    { id: 'interested_investment_solution', label: 'Interested Investment Solution', selectOptions: INVESTMENT_SOLUTIONS_CHOICES },
    { id: 'past_investments', label: 'Past Investments', selectOptions: PAST_INVESTMENTS_CHOICES },
];

const FormField = ({ id, label, value, onChange, selectOptions }) => (
    <Grid item xs={12} sm={6}>
        <FormControl variant="outlined" fullWidth margin="normal">
            <InputLabel htmlFor={id}>{label}</InputLabel>
            {selectOptions ? (
                <Select
                    id={id}
                    name={id}
                    value={value || ''}
                    onChange={onChange}
                    label={label}
                >
                    {selectOptions.map(option => (
                        <MenuItem key={option[0]} value={option[0]}>
                            {option[1]}
                        </MenuItem>
                    ))}
                </Select>
            ) : (
                <OutlinedInput
                    id={id}
                    name={id}
                    label={label}
                    value={value}
                    onChange={onChange}
                />
            )}
        </FormControl>
    </Grid>
);


const InvestingProfile = ({ update_investor_profile, profileData }) => {
    const initialFormData = {
        first_name: '',
        last_name: '',
        dob: '',
        nationality: '',
        investor_type: '',
        accredited_investor_status: '',
        investment_horizon: '',
        risk_preference: '',
        liquid_worth: '',
        referral_source: '',
        investment_method: '',
        investing_anticipation: '',
        target_investment_per_investment: '',
        interested_investment_solution: '',
        past_investments: '',
        qualified_purchaser: false,
    };

    const [formData, setFormData] = useState(initialFormData);
    const [initialData, setInitialData] = useState(initialFormData); // New state for initial values

    useEffect(() => {
        if (profileData) {

            const updatedData = {
                first_name: profileData.first_name || '',
                last_name: profileData.last_name || '',
                dob: profileData.dob || '',
                nationality: profileData.nationality || '',
                investor_type: profileData.investor_type || '',
                accredited_investor_status: profileData.accredited_investor_status || '',
                investment_horizon: profileData.investment_horizon || '',
                risk_preference: profileData.risk_preference || '',
                liquid_worth: profileData.liquid_worth || '',
                referral_source: profileData.referral_source || '',
                investment_method: profileData.investment_method || '',
                investing_anticipation: profileData.investing_anticipation || '',
                target_investment_per_investment: profileData.target_investment_per_investment || '',
                interested_investment_solution: profileData.interested_investment_solution || '',
                past_investments: profileData.past_investments || '',
                qualified_purchaser: typeof profileData.qualified_purchaser === 'boolean' ? profileData.qualified_purchaser : false,
              };
            setFormData(updatedData);
            setInitialData(updatedData);

        }
    }, [profileData]);

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value || '' });

    const onSubmit = e => {
        e.preventDefault();
        const changedFields = Object.keys(formData).reduce((acc, key) => {
            if (formData[key] !== initialData[key]) {
                acc[key] = formData[key];
            }
            return acc;
        }, {});


        update_investor_profile(changedFields);
    };


    return (
        <form onSubmit={onSubmit}>
            <CSRFToken />
            <Typography variant="h6" gutterBottom>
                Investing Profile
            </Typography>
            <Grid container spacing={2}>
                {fieldsConfig.map(({ id, label, selectOptions }) => (
                    <FormField
                        key={id}
                        id={id}
                        label={label}
                        value={formData[id]}
                        onChange={onChange}
                        selectOptions={selectOptions}
                    />
                ))}
                <Box display="flex" alignItems="center">
                    <Typography variant="body1" style={{ marginRight: '8px' }}>
                        Qualified Purchaser:
                    </Typography>
                    {formData.qualified_purchaser ? (
                        <CheckIcon color="success" /> // Checkmark icon
                    ) : (
                        <CloseIcon color="error" /> // Cross icon
                    )}
                </Box>
            </Grid>
            <Button variant="contained" color="secondary" type="submit" sx={{ mt: 3 }}>
                Update Investing Profile
            </Button>
        </form>
    );
};

const mapStateToProps = state => ({
    profileData: state.profile // Map profile data from redux state
});

export default connect(mapStateToProps, { update_investor_profile })(InvestingProfile);