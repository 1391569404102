import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { Box, Paper, Grid} from '@mui/material';
import { useParams } from 'react-router-dom';

import api from '../axiosInstance';
import Loading from '../components/general/Loading';

const RuleBook = ({title}) => {
    const { title: paramTitle } = useParams();
    const effectiveTitle = title || paramTitle;
    const [ content, setContent ] = useState('');
    const [ loading, setLoading ] = useState(true);

    useEffect( () => {

        if (effectiveTitle) {
            api.get(`api/documents/rulebooks/${effectiveTitle}/`).then(response => {
                
                setContent(response.data.content);
                setLoading(false); 
            })
            .catch(error =>
                {
                    console.error('Error fetching Markdown content:', error );
                    setLoading(false);
                });
            }
},
     [effectiveTitle]);

    return (
        <div>
            <Grid>
            {loading ?<Loading /> : 
                <Box component={Paper} style={{padding:'40px'}}>

            
                     <ReactMarkdown>{content}</ReactMarkdown>
                </Box> }
            </Grid>
        </div>
    )
};

export default RuleBook;
