import React, { useState, useEffect } from 'react';
import {
    Typography, Button, Box, Grid, Paper, TableContainer, Tab, Dialog,IconButton,
    DialogActions, DialogContent, DialogTitle
} from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { DataGrid } from '@mui/x-data-grid';
import api from '../axiosInstance';
import CheckoutForm from '../components/order/CheckoutForm';
import NumberFormatter from '../components/helpers/NumberFormatter';
import SearchBar from '../components/general/SearchBar';
import { InsertDriveFile } from '@mui/icons-material'; // Import file icon

const columns = [
    {
        field: 'logo',
        headerName: '',
        renderCell: (params) => <img alt='logo' src={params.value} style={{ maxHeight: '50px', maxWidth: '100%', borderRadius: 5, padding: '10px' }} />,
        sortable: false,
        filterable: false,
        width: 60,
    },
    { field: 'name', headerName: 'Company', width: 130 },
    {
        field: 'final_reservation_amount',
        headerName: 'Reservation Amount',
        renderCell: (params) => <NumberFormatter number={params.value} />,
        width: 200,
    },
    { field: 'equivalent_number_of_shares', headerName: '# of shares', width: 180 },
    {
        field: 'implied_share_price',
        headerName: 'Share price',
        renderCell: (params) => <NumberFormatter number={params.value} />,
        width: 180,
    },
];

const Portfolio = () => {
    const [searchQuery, setSearchQuery] = useState('');
    const [currentTab, setCurrentTab] = useState('1');
    const [open, setOpen] = useState(false);
    const [orderData, setOrderData] = useState(null);
    const [dealId, setDealId] = useState(null);
    const [orderId, setOrderId] = useState(null);
    const [filteredOrders, setFilteredOrders] = useState({
        Watchlist: [], Reserved: [], Waiting: [], Paid: [], Closed: [], Exited: []
    });

    const [signedDocuments, setSignedDocuments] = useState([]); // State for signed documents
    const unpaidColumns = [
        ...columns,
        {
            field: 'button',
            headerName: '',
            renderCell: (params) => (
                <Button variant="contained" color="secondary" onClick={() => handleClickOpen(params.row)}>
                    Make Payment
                </Button>
            ),
            width: 180,
        }
    ];



    const downloadDocument = async (documentId) => {
        try {
            const response = await api.get(`/api/documents/download_doc/${documentId}/`, {
                responseType: 'blob' // Important for file download
            });
            // Create a URL for the downloaded file and trigger the download
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `document_${documentId}.pdf`); // Name the file
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (error) {
            console.error("Error downloading document", error);
        }
    };
    



    const fetchSignedDocuments = async () => {
        try {
            const response = await api.get('/api/documents/list_doc/');
            const formattedDocs = response.data.map(doc => ({
                id: doc.id,
                label: doc.document_type, // Assuming `document_type` holds the title,
                order: doc.order
            }));
            setSignedDocuments(formattedDocs);
        } catch (error) {
            console.error("Error fetching documents", error);
        }
    };


    const handleClickOpen = (rowData) => {
        const equivalent_number_of_shares = rowData.equivalent_number_of_shares || 0;
        setOrderId(rowData.id);
        setOrderData({ price: "price_1Ps3UQCFu7uROflV4KmutHzj", equivalent_number_of_shares });
        setDealId(rowData.deal);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setDealId(null);
        setOrderData(null);
    };

    const fetchOrders = async () => {
        try {
            const { data: ordersData } = await api.get('api/orders/list/me/');
            setFilteredOrders({
                Watchlist: ordersData.filter(order => order.status === 0),
                Reserved: ordersData.filter(order => order.status === 1),
                Waiting: ordersData.filter(order => order.status === 2),
                Paid: ordersData.filter(order => order.status === 4),
                Closed: ordersData.filter(order => order.status === 5),
                Exited: ordersData.filter(order => order.status === 6),
            });
        } catch (error) {
            console.error('Error fetching orders:', error);
        }
    };

    useEffect(() => {
        fetchOrders();
        fetchSignedDocuments();
    }, []);


    const paidColumns = [
        ...columns,
        {
            field: 'signedTermSheet',
            headerName: 'Signed Term Sheet',
            renderCell: (params) => {
                const document = signedDocuments.find(doc => doc.order === params.row.id); // Match the signed document with the order ID
                return (
                    <IconButton
                    color="secondary"
                    onClick={() => downloadDocument(document.id)}
                    
                    style={{ display: 'flex', flexDirection: 'row', padding:'10px'}}
                >
                    <InsertDriveFile fontSize="medium" />
                    <Typography variant="caption" style={{ marginTop: '0.5rem' }}>
                        Download
                    </Typography>
                </IconButton>
                );
            },
            width: 180,
        },
    ];
    const handleChange = (event, newValue) => {
        setCurrentTab(newValue);
    };

    const getRows = (orders) => {
        return orders.map(order => ({
            ...order,
            button: 'Please Pay'
        }));
    };

    return (
        <div>
            <Grid container display="flex" spacing={2} justifyContent={'center'} padding="40px">
                <Grid item xs={12} lg={10} component={Paper} sx={{ width: '100%', typography: 'body1', padding: '60px' }}>
                    <Typography variant="h3" gutterBottom>Portfolio</Typography>
                    <Box width="100%" sx={{ display: 'flex' }}>
                        <SearchBar searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
                    </Box>
                    <TabContext value={currentTab}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleChange} aria-label="portfolio navigation" textColor="secondary" indicatorColor="secondary">
                                {['Watchlist', 'Reserved', 'Waiting', 'Paid', 'Closed', 'Exited'].map((label, index) => (
                                    <Tab key={index} label={label} value={(index + 1).toString()} />
                                ))}
                            </TabList>
                        </Box>
                        {['Watchlist', 'Reserved', 'Waiting', 'Paid', 'Closed', 'Exited'].map((status, index) => (
                            <TabPanel key={index} value={(index + 1).toString()}>
                                <Typography variant="h4" gutterBottom>{`${status}`}</Typography>
                                <TableContainer component={Paper} sx={{ width: '100%', maxHeight: 400 }}>
                                    <DataGrid
                                        rows={getRows(filteredOrders[status])}
                                        columns={status === 'Paid' ? paidColumns : (status === 'Reserved' ? unpaidColumns : columns)}                                        pageSize={5}
                                        rowsPerPageOptions={[5]}
                                        disableColumnMenu
                                    />
                                </TableContainer>
                            </TabPanel>
                        ))}
                    </TabContext>
                    <Button variant="contained" color="secondary" href="/investments"> Explore new offerings </Button>
                </Grid>
            </Grid>
            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth={true}
                maxWidth="lg"
                sx={{
                    width: '100%',
                    maxWidth: {
                        xs: '100%',
                        sm: '100%',
                        md: '600px',
                        lg: '800px'
                    },
                    margin: 'auto'
                }}
            >
                <DialogTitle align='center'>Complete Payment</DialogTitle>
                <DialogContent>
                    {orderData && dealId && orderId && (
                        <CheckoutForm formData={orderData} orderId={orderId} />

                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">Close</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default Portfolio;
