// StartVerificationButton.js
import React, { useState } from 'react';
import api from '../axiosInstance';
import { Button, Typography } from '@mui/material';


const StartVerificationButton = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const handleStartVerification = async () => {
        setLoading(true);
        setError(null);

        try {
            // Replace with your actual backend URL
            const response = await api.post('/api/accounts/start_verification/', {});

            if (response.status !== 200 && response.status !== 201) {
                throw new Error('Failed to start verification');
            }

            const data = response.data;
            // Redirect the user to the verification page
            window.location.href = data.verificationUrl;

        } catch (error) {
            // Handle errors, including the ones from the server
            setError(error.response?.data?.message || error.message || 'Unknown error');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <Button variant='contained' color='primary' onClick={handleStartVerification} disabled={loading}>
                {loading ? 'Starting Verification...' : 'Start Verification'}
            </Button>
            
            <Typography>{error}</Typography>

        </div>
    );
};

export default StartVerificationButton;
