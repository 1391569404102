import React from 'react';
import { Box, Typography, Checkbox, FormControlLabel } from '@mui/material';
import RuleBook from '../../pages/RuleBook';

const AcceptStep = ({ step, isAccepted, setIsAccepted }) => {
    const handleCheckboxChange = (stepId, checked) => {
        setIsAccepted((prevAccepted) => ({
            ...prevAccepted,
            [stepId]: checked,
        }));
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                alignItems: 'center',
                padding: { xs: 2, md: 4 }, // Responsive padding
                textAlign: 'center', // Center text on small screens
            }}
        >
            <Typography
                variant="h6"
                gutterBottom
                sx={{
                    fontSize: { xs: '1rem', md: '1.75rem' },
                    textAlign: 'center'
                }}
            >
                {step.question.question}
            </Typography>
            <RuleBook title={step.question.path} />
            <FormControlLabel
                control={
                    <Checkbox
                        checked={isAccepted[step.id] || false}
                        onChange={(e) => handleCheckboxChange(step.id, e.target.checked)}
                        sx={{
                            '& .MuiSvgIcon-root': {
                                fontSize: { xs: '1.5rem', md: '2rem' },
                            }, // Responsive icon size
                        }}
                    />
                }
                label={
                    <span>
                        I Accept the Terms <span style={{ color: 'red' }}>*</span>
                    </span>
                }
                sx={{
                    marginTop: 1,
                    '& .MuiFormControlLabel-label': {
                        fontSize: { xs: '1rem', md: '1.25rem' }, // Responsive label size
                    },
                }}
            />
        </Box>
    );
};

export default AcceptStep;
