import React, { useState, useEffect, useRef } from 'react';
import { Box } from '@mui/material';
import { filterStepsByCondition, loadFormData } from './screening/formHelpers';
import { names } from './screening/formConstants';
import FormStepper from '../components/screening/FormStepper';
import FormSnackbar from '../components/screening/FormSnackBar';
import FormProgressBar from '../components/screening/FormProgressBar';
import FormTitle from '../components/screening/FormTitle';
import StepContainer from '../components/screening/StepContainer';
import api from '../axiosInstance';
import Cookies from 'js-cookie';

const ScreeningForm = ({ formName, onFormComplete }) => {
    const [formSteps, setFormSteps] = useState([]);
    const [currentStep, setCurrentStep] = useState(0);
    const [formData, setFormData] = useState({});
    const [isAccepted, setIsAccepted] = useState({});
    const [error, setError] = useState('');
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const ScrollHere = useRef(null);

    // Custom scroll function with an offset
    const handleScrollToForm = () => {
        if (ScrollHere.current) {
            const offsetTop = ScrollHere.current.getBoundingClientRect().top + window.scrollY;
            const isMobile = window.innerWidth < 768;
            const additionalOffset = isMobile ? -170 : -50; // Adjust this value as needed for spacing
            const scrollToPosition = offsetTop + additionalOffset;
            // Use setTimeout to allow for rendering before scrolling
            setTimeout(() => {
                window.scrollTo({
                    top: Math.max(scrollToPosition, 0),
                                behavior: 'smooth'
                });
            }, 100); // Adjust timeout as necessary
        }
    };

    useEffect(() => {
        const fetchFormData = async () => {
            const response = await loadFormData(formName);
            const filteredSteps = filterStepsByCondition(response.steps, formData);
            setFormSteps(filteredSteps);
        };
        fetchFormData();
    }, [formName, formData]);

    /*
    const isFormValid = () => {
        const currentStepData = formSteps[currentStep];

        if (!currentStepData) return true; // If no current step data, assume valid

        const { isValid } = validateRequiredFields(currentStepData);
        return isValid;
    };
    */

    const handleNext = () => {
        const currentStepData = formSteps[currentStep];
        const { isValid, message } = validateRequiredFields(currentStepData);

        if (isValid) {
            setCurrentStep((prevStep) => {
                const nextStep = prevStep + 1;
                handleScrollToForm(); // Custom scroll with offset for reliability
                return nextStep;
            });
            clearError();
        } else {
            showError(message); // Show error message if validation fails
        }
    };

    const handleBack = () => {
        if (currentStep > 0) {
            setCurrentStep((prevStep) => prevStep - 1);
            handleScrollToForm(); // Custom scroll with offset for reliability
            clearError();
        }
    };

    const clearError = () => {
        setError('');
    };

    const showError = (message) => {
        setError(message);
        setOpenSnackBar(true);
    };

    const validateRequiredFields = (currentStepData) => {
        let validationResult = { isValid: true, message: 'Please answer all the questions' }; // Initialize validation result

        switch (currentStepData.question.type) {
            case 'accept':
                validationResult.isValid = isAccepted[currentStepData.id];
                break;
            case 'check':
                validationResult.isValid = (formData[currentStepData.id] || []).length > 0;
                break;
            case 'choice':
            case 'input':
            case 'autocomplete':
                validationResult.isValid = Boolean(formData[currentStepData.id]);
                break;
            case 'date':
                const dateOfBirth = formData[currentStepData.id];
                if (!dateOfBirth) {
                    validationResult.isValid = false; // No date provided
                } else {
                    const dob = new Date(dateOfBirth); // Create a Date object from the string
                    const today = new Date();
                    const age = today.getFullYear() - dob.getFullYear(); // Calculate age

                    // Check if the person is 18 years or older
                    const isEighteenOrOlder = age > 18 ||
                    (age === 18 && today.getMonth() > dob.getMonth()) ||
                    (age === 18 && today.getMonth() === dob.getMonth() && today.getDate() >= dob.getDate());

                    if (!isEighteenOrOlder) {
                        validationResult.isValid = false;
                        validationResult.message = 'You must be above 18 to invest through Equiti Bridge.';
                    }
                }
                break;
            default:
                validationResult.isValid = false; // Return false if type is unhandled
        }

        return validationResult; // Return the validation result with message
    };

    const handleSubmit = async () => {
        try {
            const csrftoken = Cookies.get('csrftoken');

            if (!csrftoken) throw new Error('CSRF Token not found');

            await api.put(`/api/profile/screening/${formName}`, formData, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'X-CSRFToken': csrftoken,
                }
            });

            if (onFormComplete) onFormComplete();
            handleScrollToForm(); // Custom scroll with offset for reliability

        } catch (err) {
            showError('Error submitting the form. Please try again');
            console.error('Error during the form submission:', err);
        }
    };

    const isLastStep = currentStep === formSteps.length - 1;
    const isFirstStep = currentStep === 0;

    return (
        <Box ref={ScrollHere} sx={{ padding: { xs: 1, md: 4 } }}>
        <FormProgressBar currentStep={currentStep} totalSteps={formSteps.length} />
        <FormTitle title={names[formName] || 'Screening'} />

        {/* Ensure to check if the current step is valid */}
        {formSteps.length > 0 && (
            <StepContainer
            key={currentStep}
            step={formSteps.length > 0 ? formSteps[currentStep] : null}
            formData={formData}
            setFormData={setFormData}
            isAccepted={isAccepted}
            setIsAccepted={setIsAccepted}
            />
        )}
        <FormStepper
        isLastStep={isLastStep}
        isFirstStep={isFirstStep}
        onNext={handleNext}
        onBack={handleBack}
        onSubmit={handleSubmit}
        //isNextDisabled={!isFormValid()} // Check validity before allowing next
        currentStep={currentStep}
        totalSteps={formSteps.length}
        />
        <FormSnackbar
        open={openSnackBar}
        onClose={() => setOpenSnackBar(false)}
        message={error}
        />
        </Box>
    );
};

export default ScreeningForm;
