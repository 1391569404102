import React, { useState, useEffect } from 'react';
import { Paper, Box, Tab, Grid, Typography } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import BasicProfile from '../components/profiles/BasicProfile'; 
import InvestingProfile from  '../components/profiles/InvestingProfile';
import VerifyProfile from  '../components/profiles/VerifyProfile';
import { useLocation } from 'react-router-dom'; // Import useLocation

const Profile = () => {

    const location = useLocation(); // Get the current location
    const [value, setValue] = useState("1"); 

     useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const tab = queryParams.get('tab');
        if (tab) {
            setValue(tab);
        }
    }, [location]);


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Grid container display='flex' spacing={2} justifyContent={'center'} padding='40px'>
            <Grid item xs={12} lg={10} component={Paper} sx={{ width: '100%', typography: 'body1', padding: '60px' }}>
                <Typography variant="h3" gutterBottom>
                    Your Account
                </Typography>

                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleChange} aria-label="profile navigation" textColor="secondary" indicatorColor="secondary">
                            <Tab label="Basic Information" value="1" />
                            <Tab label="Investing Suitability" value="2" />
                            <Tab label="Profile Verification" value="3" />
                        </TabList>
                    </Box>

                    <TabPanel value="1">
                        <BasicProfile />
                    </TabPanel>
                    <TabPanel value="2">
                        <InvestingProfile />
                    </TabPanel>
                    <TabPanel value="3">
                        <VerifyProfile />
                    </TabPanel>
                </TabContext>
            </Grid>
        </Grid>
    );
};

export default Profile;
