import React from 'react';
import { Grid } from '@mui/material';

const ErrorState = ({ error, theme }) => {
    return (
        <Grid style={{ padding: 20}}>
            <Grid container flexDirection="column" alignItems="center">
                Error: {error}
            </Grid>
        </Grid>
    );
};

export default ErrorState;
