import React from 'react';
import { CircularProgress, Box, Typography } from '@mui/material';
import { styled } from '@mui/material';

const useStyles = styled( (theme) => ({

    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        flexDirection: 'column',
    },
    text: {
        marginTop: theme.spacing(2),
        color: theme.palette.text.primary
    },

}));

const Loading = ({ message = 'Loading'}) => {
    const classes = useStyles();

    return (

        
        <Box className={classes.root}>
            <CircularProgress />
            <Typography variant='h6' className={classes.text}>
                { message }
            </Typography>
                    </Box>
    );

};

export default Loading;
