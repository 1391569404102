import React from 'react';
import { Box, Typography } from '@mui/material';

const MessageStep = ({ step }) => {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'center' }}>
            <Typography variant="body1" gutterBottom>
                <span dangerouslySetInnerHTML={{ __html: step.message }} />
            </Typography>
        </Box>
    );
};

export default MessageStep;
