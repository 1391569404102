import Cookies from 'js-cookie';
import api from '../axiosInstance';
import {
    LOAD_INVESTOR_PROFILE_SUCCESS,
    LOAD_INVESTOR_PROFILE_FAIL,
    UPDATE_INVESTOR_PROFILE_SUCCESS,
    UPDATE_INVESTOR_PROFILE_FAIL
} from './types';

export const load_investor_profile = () => async dispatch => {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    };

    try {

        const res = await api.get('/api/profile/investor/', config);

        if (res.data.error) {

            dispatch({
                type: LOAD_INVESTOR_PROFILE_FAIL
            })
        }
        else {

            dispatch({
                type: LOAD_INVESTOR_PROFILE_SUCCESS,
                payload: res.data
            })
        }

    } catch (err) {

        dispatch({
            type: LOAD_INVESTOR_PROFILE_FAIL
        })

    }


};

export const update_investor_profile = (profileData) => async dispatch => {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRFToken': Cookies.get('csrftoken')
        }
    };
   
    try {
        console.log(profileData)
       

        const res = await api.put('/api/profile/investor/update/',profileData, config);
       
        if (res.data.email) {
            dispatch({
                type: UPDATE_INVESTOR_PROFILE_SUCCESS,
                payload: res.data
            })
        }
        else {
            dispatch({
                type: UPDATE_INVESTOR_PROFILE_FAIL
            })
           
        }

    } catch (err) {
        dispatch({
            type: UPDATE_INVESTOR_PROFILE_FAIL
        })
    }

}