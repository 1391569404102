import { useState, useEffect } from 'react';
import api from '../axiosInstance';

const useFetchCompanies = () => {
    const [companies, setCompanies] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchCompanies = async () => {
            try {
                const response = await api.get('/api/products/companies/');
                setCompanies(response.data);
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchCompanies();
    }, []);

    return { companies, loading, error };
};

export default useFetchCompanies;
