import React, { useEffect, useState, useRef } from 'react';
import { TextField, Grid, Paper, Typography, CircularProgress, Alert, Button, Box } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import { alpha } from '@mui/material/styles';
import { useSelector, useDispatch } from 'react-redux';
import { updateOrderData, setValueValidity } from '../../actions/order';

const PLATFORM_FEE_PERCENTAGE = 0.05;

const ReservationDetails = () => {
  const dispatch = useDispatch();

  // Redux state
  const formData = useSelector((state) => state.order.formData);
  const error = useSelector((state) => state.order.error);
  const deal = useSelector((state) => state.order.dealData);
  const company = useSelector((state) => state.order.companyData);

  // Local state
  const [helperText, setHelperText] = useState('');
  const [inputAmount, setInputAmount] = useState(0);
  const [calculatedFee, setCalculatedFee] = useState(0);
  const [isProcessing, setIsProcessing] = useState(false);
  const [shareRanges, setShareRanges] = useState({
    bottom: 0,
    top: 0
  });
  const debounceTimer = useRef(null);

  // Calculate total amount including platform fee
  const calculateTotalAmount = (baseAmount) => {
    return baseAmount * (1 + PLATFORM_FEE_PERCENTAGE);
  };

  // Calculate share count from reservation amount
  const calculateShares = (amount) => {
    if (!deal?.implied_share_price) return 0;
    const baseAmount = amount / (1 + PLATFORM_FEE_PERCENTAGE);
    return Math.floor(baseAmount / deal.implied_share_price);
  };

  // Calculate platform fee
  const calculatePlatformFee = (amount) => {
    const shareCount = calculateShares(amount);
    const baseAmount = shareCount * (deal?.implied_share_price || 0);
    return amount - baseAmount;
  };

  // Initialize with deal data
  useEffect(() => {
    if (!deal) return;

    const minSharesValue = deal.implied_share_price * deal.deal_min_share_count;
    const totalMinAmount = calculateTotalAmount(minSharesValue);
    const initialFee = calculatePlatformFee(totalMinAmount);
    
    setInputAmount(totalMinAmount);
    setCalculatedFee(initialFee);
    updateShareRanges(totalMinAmount);
    
    const initialFormData = {
      reservation_amount: totalMinAmount,
      share_price: deal.implied_share_price,
      equivalent_number_of_shares: deal.deal_min_share_count,
      final_reservation_amount: minSharesValue,
    };

    dispatch(updateOrderData(initialFormData));
    dispatch(setValueValidity(true));
  }, [deal, dispatch]);

  // Update share range buttons
  const updateShareRanges = (currentAmount) => {
    if (!deal) return;

    const currentShares = calculateShares(currentAmount);
    const bottomShares = Math.max(
      currentShares - deal.broker_min_increment,
      deal.deal_min_share_count
    );
    const topShares = currentShares + deal.broker_min_increment;

    setShareRanges({
      bottom: calculateTotalAmount(bottomShares * deal.implied_share_price),
      top: calculateTotalAmount(topShares * deal.implied_share_price)
    });
  };

  const formatCurrency = (value) => {
    if (!value && value !== 0) return '';
    return `£${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
  };

  // Parse currency string back to number
  const parseCurrency = (value) => {
    if (!value) return '';
    return value.replace(/[^0-9.-]+/g, '');
  };

  // Modified handleInputChange to handle currency formatting
  const handleInputChange = (event) => {
    const rawValue = parseCurrency(event.target.value);
    const value = parseFloat(rawValue);
    setInputAmount(value);
    setIsProcessing(true);

    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }

    debounceTimer.current = setTimeout(() => {
      processReservationAmount(value);
    }, 500);
  };
  const processReservationAmount = (amount) => {
    if (!Number.isFinite(amount) || amount <= 0) {
      setHelperText('Please enter a valid amount');
      dispatch(setValueValidity(false));
      setIsProcessing(false);
      return;
    }
  
    // Calculate minimum required amount
    const minSharesValue = deal.implied_share_price * deal.deal_min_share_count;
    const minTotalAmount = calculateTotalAmount(minSharesValue);
  
    // If amount is less than minimum, use minimum values
    if (amount < minTotalAmount) {
      const minFee = calculatePlatformFee(minTotalAmount);
      setHelperText(`Minimum investment is ${formatCurrency(minTotalAmount)}`);
      setCalculatedFee(minFee);
      setInputAmount(minTotalAmount);
      updateShareRanges(minTotalAmount);
      
      const updatedFormData = {
        reservation_amount: minTotalAmount,
        share_price: deal.implied_share_price,
        equivalent_number_of_shares: deal.deal_min_share_count,
        final_reservation_amount: minSharesValue,
      };
      
      dispatch(updateOrderData(updatedFormData));
      dispatch(setValueValidity(false));
      setIsProcessing(false);
      return;
    }
  
    // Normal processing for valid amounts
    const shareCount = calculateShares(amount);
    const baseAmount = shareCount * deal.implied_share_price;
    const totalAmount = calculateTotalAmount(baseAmount);
    const fee = calculatePlatformFee(totalAmount);
  
    let isValid = true;
    if (shareCount > deal.available_shares) {
      const availableShares = deal.available_shares;
      const waitlistShares = shareCount - availableShares;
      
      // Calculate values for available shares
      const availableBaseAmount = availableShares * deal.implied_share_price;
      const availableTotalAmount = calculateTotalAmount(availableBaseAmount);
      
      // Calculate values for waitlist shares
      const waitlistBaseAmount = waitlistShares * deal.implied_share_price;
      const waitlistTotalAmount = calculateTotalAmount(waitlistBaseAmount);
  
      setHelperText(
        `Not enough available shares. ${availableShares} shares (${formatCurrency(availableTotalAmount)}) ` +
        `will be booked, and ${waitlistShares} shares (${formatCurrency(waitlistTotalAmount)}) ` +
        `will be placed on a waiting list`
      );
    } else {
      setHelperText(`Amount adjusted to ${formatCurrency(totalAmount)} for whole shares`);
    }
  
    const updatedFormData = {
      reservation_amount: totalAmount,
      share_price: deal.implied_share_price,
      equivalent_number_of_shares: shareCount,
      final_reservation_amount: baseAmount,
    };
  
    setCalculatedFee(fee);
    dispatch(updateOrderData(updatedFormData));
    dispatch(setValueValidity(isValid));
    setInputAmount(totalAmount);
    updateShareRanges(totalAmount);
    setIsProcessing(false);
  };

  const renderTextField = (label, name, value, disabled = false) => {
    const isCurrencyField = ['reservation_amount', 'share_price', 'final_reservation_amount', 'platform_fee'].includes(name);
    const displayValue = isCurrencyField ? formatCurrency(value) : value;

    return (
    <Grid item xs={12} lg={12}>
      <TextField
        required={!disabled}
        label={label}
        name={name}
        value={displayValue || ''}
        disabled={disabled}
        onChange={disabled ? undefined : handleInputChange}
        fullWidth
        error={!disabled && !!error}
        helperText={disabled ? null : helperText || error}
        sx={{
          '& .MuiFormHelperText-root': {
            color: (theme) => {
              if (disabled) return theme.palette.text.disabled;
              if (error) return theme.palette.error.main;
              if (helperText) return theme.palette.secondary.dark;
              return theme.palette.text.secondary;
            }
          }
        }}
        InputProps={{
          endAdornment: !disabled && name === 'reservation_amount' ? (
            <InputAdornment position="end">
              <Box sx={{ display: 'flex', gap: 1 }}>
                {Number.isFinite(shareRanges.bottom) && 
                 shareRanges.bottom >= (deal?.minimum_investment_size || 0) && (
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => processReservationAmount(shareRanges.bottom)}
                    startIcon={<KeyboardDoubleArrowDownIcon />}
                    sx={{
                      borderRadius: '16px',
                      color: 'success.main',
                      borderColor: 'success.main',
                      minWidth: '100px',
                      '&:hover': {
                        backgroundColor: alpha('#4caf50', 0.1),
                        borderColor: 'success.main',
                      },
                    }}
                  >
                  {formatCurrency(shareRanges.bottom)}
                  </Button>
                )}
                {Number.isFinite(shareRanges.top) && (
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => processReservationAmount(shareRanges.top)}
                    startIcon={<KeyboardDoubleArrowUpIcon />}
                    sx={{
                      borderRadius: '16px',
                      color: 'primary.main',
                      minWidth: '100px',
                      '&:hover': {
                        backgroundColor: alpha('#1976d2', 0.1),
                      },
                    }}
                  >
                     {formatCurrency(shareRanges.top)}
                  </Button>
                )}
              </Box>
            </InputAdornment>
          ) : null,
        }}
      />
    </Grid>
    )
  };

  if (!deal || !company) {
    return <CircularProgress />;
  }

  return (
    <Box sx={{ padding: '20px' }}>
      <Grid container spacing={2}>
        {company && (
          <Grid item xs={12} lg={3}>
            <Paper sx={{ 
              display: 'flex', 
              flexDirection: 'column', 
              backgroundColor: 'white', 
              padding: '50px', 
              alignItems: 'center', 
              justifyContent: 'center', 
              height: '10vh' 
            }}>
              <img alt="Company Logo" src={company.logo} width="50vh" />
              <Typography variant="h4" marginTop="30px">{company.name}</Typography>
            </Paper>
          </Grid>
        )}

        <Grid item xs={12} lg={9}>
          <Grid container spacing={2} sx={{ display: 'flex', flexDirection: 'column' }}>
            {formData && (
              <>
                {renderTextField('Reservation amount', 'reservation_amount', inputAmount, false)}
                {renderTextField('Platform Fee', 'platform_fee', calculatedFee.toFixed(2), true)}
                {renderTextField('Reserved Value of Stocks (excl. Platform Fee)', 'final_reservation_amount', formData.final_reservation_amount, true)}

                {renderTextField('Share Price (excl. Platform Fee)', 'share_price', formData.share_price, true)}
                {renderTextField('Share amount', 'share_amount', formData.equivalent_number_of_shares, true)}
                
              </>
            )}
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="body1" sx={{ fontSize: '0.8em' }}>
            EquitiBridge does not guarantee the availability of Deals. Minimum investment before fees: {formatCurrency(deal.implied_share_price * deal.deal_min_share_count)}.
            Reservation Amounts below this minimum are adjusted.
          </Typography>
          <Typography variant="body1" sx={{ fontSize: '0.8em' }}>
            The reservation amount includes the Platform Fee of {PLATFORM_FEE_PERCENTAGE * 100}% of the base amount.
          </Typography>
        </Grid>

        <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
          <Alert severity="info" sx={{ fontSize: '0.8rem', marginTop: '20px' }}>
            Reservations are processed in order of commitment. Once capacity is reached, reservations will be placed on the waiting list.
          </Alert>
        </Box>
      </Grid>
    </Box>
  );
};

export default ReservationDetails;